import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ToolboxState } from './toolbox.state';
import { toolboxFeatureKey } from './toolbox.reducer';
import { selectQueryParams } from '../../../store/router.selector';
import { Params } from '@angular/router';

export const selectToolboxState = createFeatureSelector<ToolboxState>(toolboxFeatureKey);
export const selectFilesToolState = createSelector(selectToolboxState, ({ filesTool }) => filesTool);
export const selectCommonToolsState = createSelector(selectToolboxState, ({ commonTools }) => commonTools);
export const selectIsFilesListLoading = createSelector(
  selectFilesToolState,
  ({ isFilesListLoading }) => isFilesListLoading
);
export const selectIsFilesLoaded = createSelector(selectFilesToolState, ({ isFilesListLoaded }) => isFilesListLoaded);
export const selectFilesList = createSelector(selectFilesToolState, ({ files }) => files);
export const selectSelectedFile = createSelector(selectFilesToolState, ({ selectedFile }) => selectedFile);
export const selectSelectedFileId = createSelector(selectSelectedFile, (selectedFile) =>
  !!selectedFile ? selectedFile.id : null
);

export const selectSelectedFileStatus = createSelector(selectSelectedFile, (selectedFile) =>
  !!selectedFile ? selectedFile.status : null
);
export const selectUsersList = createSelector(selectFilesToolState, ({ users }) => users);
export const selectIsUsersListLoading = createSelector(
  selectFilesToolState,
  ({ isUsersListLoading }) => isUsersListLoading
);
export const selectIsUsersListLoaded = createSelector(
  selectFilesToolState,
  ({ isUsersListLoaded }) => isUsersListLoaded
);
export const selectSelectedUser = createSelector(selectFilesToolState, ({ selectedUser }) => selectedUser);
export const selectSelectedUserId = createSelector(selectSelectedUser, (selectedUser) =>
  !!selectedUser ? selectedUser.id : null
);

export const selectFileUploadProgress = createSelector(
  selectToolboxState,
  ({ saveTool }) => saveTool.importFile.progress
);
export const selectHorizontalZoom = createSelector(selectCommonToolsState, ({ horizontalZoom }) => horizontalZoom);
export const selectVerticalZoom = createSelector(selectCommonToolsState, ({ verticalZoom }) => verticalZoom);

// ========= fileId, userId Query Params Router Selectors ========= //
export const selectFileIdQueryParam = createSelector(selectQueryParams, (params: Params) =>
  'fileId' in params ? params['fileId'] : null
);

export const selectUserIdQueryParam = createSelector(selectQueryParams, (params: Params) =>
  'userId' in params ? params['userId'] : null
);
