import {
  faAlignLeft,
  faAlignRight,
  faAngleLeft,
  faArrowsLeftRight,
  faArrowsUpDown,
  faBackward,
  faBackwardStep,
  faBars,
  faCameraRetro,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleDot,
  faCircleQuestion,
  faClose,
  faCloudArrowUp,
  faEye,
  faEyeSlash,
  faFileAudio,
  faFileCirclePlus,
  faFileCircleQuestion,
  faFileLines,
  faFolderArrowDown,
  faForward,
  faForwardStep,
  faHandsClapping,
  faHeadphonesSimple,
  faListUl,
  faMars,
  faMarsAndVenus,
  faPause,
  faPlay,
  faPlus,
  faRotateRight,
  faSort,
  faSortDown,
  faSortUp,
  faTrash,
  faUserPen,
  faVenus,
  faCircleInfo,
  faFolderArrowUp,
  faFolderMusic,
  faGear,
  faDownload,
  faCloudDownload,
} from '@fortawesome/pro-solid-svg-icons';

export const SOLID_ICONS = [
  faMars,
  faVenus,
  faMarsAndVenus,
  faAngleLeft,
  faBackward,
  faForward,
  faHandsClapping,
  faHeadphonesSimple,
  faCameraRetro,
  faBars,
  faFileAudio,
  faBackwardStep,
  faClose,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faForwardStep,
  faEye,
  faEyeSlash,
  faSort,
  faSortUp,
  faSortDown,
  faListUl,
  faPlus,
  faCloudArrowUp,
  faFileCirclePlus,
  faFileCircleQuestion,
  faCircleDot,
  faPlay,
  faPause,
  faTrash,
  faUserPen,
  faRotateRight,
  faCircleQuestion,
  faArrowsLeftRight,
  faArrowsUpDown,
  faFileLines,
  faAlignRight,
  faAlignLeft,
  faCircleInfo,
  faFolderArrowUp,
  faFolderMusic,
  faGear,
  faDownload,
  faCloudDownload,
  faFolderArrowDown,
];
