<div class="loading-indicator">
  <button
    class="btn btn-ghost loading"
    [ngClass]="{
      navigation: mode === LOADING_MODES.navigation,
      'data-fetching': mode === LOADING_MODES.dataFetching
    }"
  ></button>
  <p *ngIf="mode === LOADING_MODES.dataFetching">Loading...</p>
</div>
