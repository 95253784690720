import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuItemTypesEnum } from '@enums';
import { StoreService } from '../../../services/store/store.service';
import { Observable } from 'rxjs';
import { NotificationResponseInterface } from '@interfaces';

@Component({
  selector: 'at-custom-selection-menu',
  templateUrl: './custom-selection-menu.component.html',
  styleUrls: ['./custom-selection-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomSelectionMenuComponent {
  @Input() itemsData: any[] = [];
  @Input() menuItemType: MenuItemTypesEnum;

  selectedTheme$: Observable<string> = this.storeService.getTheme();
  selectedNotification$: Observable<NotificationResponseInterface> = this.storeService.getSelectNotifications();
  isNotificationsLoading: Observable<boolean> = this.storeService.getNotificationsLoading();

  MENU_ITEM_TYPE = MenuItemTypesEnum;
  constructor(private storeService: StoreService) {}

  onSelectTheme(theme: string): void {
    this.storeService.setTheme(theme);
  }

  onSelectNotification(selectedNotification: NotificationResponseInterface): void {
    this.storeService.setSelectedNotification(selectedNotification);
  }

  getMoreNotification(): void {
    this.storeService.loadMoreNotifications();
  }
}
