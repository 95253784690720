import { createFeatureSelector, createSelector } from '@ngrx/store';
import { annotationFeatureKey } from './annotation.reducer';
import { AnnotationState } from './annotation.state';

export const selectAnnotationState = createFeatureSelector<AnnotationState>(annotationFeatureKey);

export const selectToolbarState = createSelector(selectAnnotationState, ({ toolbar }) => toolbar);
export const selectTranscriptState = createSelector(selectAnnotationState, ({ transcript }) => transcript);
export const selectWaveState = createSelector(selectAnnotationState, ({ wave }) => wave);
export const selectSegmentsState = createSelector(selectAnnotationState, ({ segments }) => segments);
export const selectRequestReviewState = createSelector(selectAnnotationState, ({ requestReview }) => requestReview);
export const selectReviewNotificationsState = createSelector(
  selectAnnotationState,
  ({ reviewNotifications }) => reviewNotifications
);

// ============= Stats =============== //
export const selectTranscriptStats = createSelector(selectTranscriptState, ({ stats }) => stats);
export const selectTranscriptStatsData = createSelector(selectTranscriptStats, ({ details }) => details);
export const selectTranscriptStatsLoading = createSelector(selectTranscriptStats, ({ isLoading }) => isLoading);

// ============== Toolbar ================ //
export const selectSelectedTool = createSelector(selectToolbarState, ({ selectedTool }) => selectedTool);
export const isToolSelected = createSelector(selectSelectedTool, (selectedTool) => !!selectedTool);

//================= Transcript =================== //
export const selectTranscriptData = createSelector(selectTranscriptState, ({ transcriptData }) => transcriptData);
export const selectTranscriptLoading = createSelector(
  selectTranscriptState,
  ({ isTranscriptLoading }) => isTranscriptLoading
);
export const selectTranscriptDirection = createSelector(
  selectTranscriptState,
  ({ transcriptDirection }) => transcriptDirection
);

export const selectSpeakersSuggestions = createSelector(
  selectTranscriptState,
  ({ speakersSuggestions: { suggestions } }) => suggestions
);

export const selectSpeakerSuggestionLoading = createSelector(
  selectTranscriptState,
  ({ speakersSuggestions: { isLoading } }) => isLoading
);

export const selectSelectedSpeaker = createSelector(
  selectTranscriptState,
  ({ speakersSuggestions: { selectedSpeaker } }) => selectedSpeaker
);
// ================ Wave =============== //
export const selectEncodedWaveParams = createSelector(selectWaveState, ({ waveParams }) => waveParams);
export const selectIsWaveLoading = createSelector(selectWaveState, ({ isLoading }) => isLoading);
export const selectIsWaveLoaded = createSelector(selectWaveState, ({ isLoaded }) => isLoaded);
export const selectSelectedRegion = createSelector(selectWaveState, ({ selectedRegion }) => selectedRegion);
export const selectCurrentWaveProgress = createSelector(selectWaveState, ({ currentProgress }) => currentProgress);
export const selectBufferRegion = createSelector(selectWaveState, ({ bufferRegion }) => bufferRegion);

// ================= Segments =================//
export const selectSegmentSaveLoading = createSelector(selectSegmentsState, ({ saveLoading }) => saveLoading);
export const selectSegmentDeleteLoading = createSelector(selectSegmentsState, ({ deleteLoading }) => deleteLoading);
export const selectIsSegmentDeletionModalState = createSelector(
  selectSegmentsState,
  ({ segmentDeletionModalState }) => segmentDeletionModalState
);
export const selectSegmentNavigationLoading = createSelector(
  selectSegmentsState,
  ({ navigationLoading }) => navigationLoading
);

// =============== Request Review =============== //
export const selectRequestReviewAdminList = createSelector(selectRequestReviewState, ({ adminList }) => adminList);
export const selectRequestReviewAdminListLoading = createSelector(selectRequestReviewState, ({ loading }) => loading);
export const selectSelectedReviewerId = createSelector(
  selectRequestReviewState,
  ({ selectedReviewerId }) => selectedReviewerId
);
export const selectReviewNote = createSelector(selectRequestReviewState, ({ reviewNote }) => reviewNote);

// ================= Review Notifications ================== //
export const selectNotifications = createSelector(selectReviewNotificationsState, ({ notifications }) => notifications);
export const selectNotificationsLoading = createSelector(selectReviewNotificationsState, ({ loading }) => loading);
export const selectSelectedNotification = createSelector(
  selectReviewNotificationsState,
  ({ selectedNotification }) => selectedNotification
);
