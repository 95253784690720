<div class="root-container">
  <at-navbar [currentModule]="currentModule$ | async"></at-navbar>
  <div class="content-holder">
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="loading$ | async" class="navigation-loading-indicator">
    <at-custom-loading-indicator></at-custom-loading-indicator>
  </div>
  <at-custom-toast
    *ngIf="isToast$ | async"
    [message]="toastMessage$ | async"
    [type]="toastType$ | async"
    (closed)="closeToast()"
  ></at-custom-toast>
</div>
