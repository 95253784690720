import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState } from './dashboard.state';
import { dashboardFeatureKey } from './dashboard.reducer';

export const selectDashboardState = createFeatureSelector<DashboardState>(dashboardFeatureKey);

export const selectAdminDashboardState = createSelector(selectDashboardState, ({ admin }) => admin);
export const selectAnnotatorDashboardState = createSelector(selectDashboardState, ({ annotator }) => annotator);
export const selectUserDashboardState = createSelector(selectDashboardState, ({ user }) => user);

// ===== Files ===== //

export const selectFilesState = createSelector(selectAdminDashboardState, ({ files }) => files);
export const selectFilesCount = createSelector(selectFilesState, ({ count }) => count);
export const selectFilesLoading = createSelector(selectFilesState, ({ isLoading }) => isLoading);
export const selectFilesUploadCompleted = createSelector(
  selectFilesState,
  ({ filesUploadCompleted }) => filesUploadCompleted
);
export const selectFilesList = createSelector(selectFilesState, ({ list }) => list);
export const selectFilesUploadList = createSelector(selectFilesState, ({ filesUploadList }) => filesUploadList);
export const selectFilesUploadProgress = createSelector(
  selectFilesState,
  ({ filesUploadProgress }) => filesUploadProgress
);

// ===== Users ===== //

export const selectUsersState = createSelector(selectAdminDashboardState, ({ users }) => users);
export const selectUsersCount = createSelector(selectUsersState, ({ count }) => count);
export const selectUsersLoading = createSelector(selectUsersState, ({ isLoading }) => isLoading);
export const selectUsersList = createSelector(selectUsersState, ({ list }) => list);
export const selectAssignedFilesListByUserLoading = createSelector(
  selectUserDashboardState,
  ({ assignedFiles }) => assignedFiles.isLoading
);
export const selectAssignedFilesListByUser = createSelector(
  selectUserDashboardState,
  ({ assignedFiles }) => assignedFiles.list
);

// ===== Projects ===== //

export const selectProjectsState = createSelector(selectAdminDashboardState, ({ projects }) => projects);
export const selectProjectsCount = createSelector(selectProjectsState, ({ count }) => count);
export const selectProjectsLoading = createSelector(selectProjectsState, ({ isLoading }) => isLoading);
export const selectProjectsList = createSelector(selectProjectsState, ({ list }) => list);

// ======== Annotator Dashboard ====== //
export const selectAnnotatorFilesStatsLoading = createSelector(
  selectAnnotatorDashboardState,
  ({ filesStatusStats }) => filesStatusStats.isLoading
);
export const selectAnnotatorFilesStats = createSelector(
  selectAnnotatorDashboardState,
  ({ filesStatusStats }) => filesStatusStats.statistics
);
export const selectAnnotatorFilesStatsFailed = createSelector(
  selectAnnotatorDashboardState,
  ({ filesStatusStats }) => filesStatusStats.isFailed
);
