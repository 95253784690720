<div class="navbar navbar-container">
  <div *ngIf="(isLoggedIn$ | async) && (isAnnotationModuleSelected$ | async)" class="dropdown">
    <label tabindex="0" class="btn btn-ghost hover:bg-base-300">
      <fa-icon [icon]="['fas', 'bars']"></fa-icon>
    </label>
    <ul tabindex="0" class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-300 rounded-box w-52">
      <ng-container *ngIf="isAdmin$ | async">
        <li *ngFor="let navigationItem of NAVIGATION_LIST.admin" class="my-0.5">
          <a
            routerLinkActive="active"
            [routerLink]="[navigationItem.single ? '' : currentModule, navigationItem.route]"
          >
            {{ navigationItem.title }}
          </a>
        </li>
      </ng-container>
      <ng-container *ngIf="isUser$ | async">
        <li *ngFor="let navigationItem of NAVIGATION_LIST.user" class="my-0.5">
          <a
            routerLinkActive="active"
            [routerLink]="[navigationItem.single ? '' : currentModule, navigationItem.route]"
          >
            {{ navigationItem.title }}
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="flex-1">
    <a routerLink="/" class="btn btn-ghost hover:bg-base-300 normal-case text-xl">
      <span class="text-primary">Annotation</span>
      Tool
    </a>
  </div>
  <div class="flex-0">
    <div class="dropdown dropdown-end">
      <label tabindex="0" class="normal-case gap-2 btn btn-ghost m-1">
        <fa-icon size="lg" [icon]="['fad', 'swatchbook']"></fa-icon>
        <span class="font-bold">Theme</span>
        <fa-icon icon="chevron-down"></fa-icon>
      </label>
      <div class="dropdown-content menu selection-menu-container">
        <at-custom-selection-menu [menuItemType]="MENU_ITEM_TYPE.theme" [itemsData]="THEMES"></at-custom-selection-menu>
      </div>
    </div>
    <div class="dropdown dropdown-end">
      <button *ngIf="isAdmin$ | async" class="btn btn-ghost btn-square p-2">
        <div class="indicator">
          <fa-icon [icon]="['fal', 'bell']" size="lg"></fa-icon>
          <span
            *ngIf="unseenNotificationsCount$ | async"
            class="indicator-item indicator-top indicator-end badge-xs badge badge-secondary"
          >
            {{ unseenNotificationsCount$ | async }}
          </span>
        </div>
      </button>
      <div class="dropdown-content menu selection-menu-container" *ngIf="(notifications$ | async)?.length">
        <at-custom-selection-menu
          [menuItemType]="MENU_ITEM_TYPE.notification"
          [itemsData]="notifications$ | async"
        ></at-custom-selection-menu>
      </div>
    </div>

    <div *ngIf="(isLoggedIn$ | async) && (isUserInfoPresent$ | async)" class="dropdown dropdown-end">
      <label tabindex="0">
        <at-custom-avatar [text]="userInitials$ | async"></at-custom-avatar>
      </label>
      <ul tabindex="0" class="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-300 rounded-box w-52">
        <li>
          <a>{{ userFullName$ | async }}</a>
        </li>
        <div class="divider my-0"></div>
        <li (click)="logout()"><a>Logout</a></li>
      </ul>
    </div>
  </div>
</div>
