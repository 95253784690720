import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthActions } from '../../components/auth/store/action-types';
import { filter, Observable } from 'rxjs';
import {
  currentUser,
  isAdmin,
  isLoggedIn,
  isLoginLoading,
  isUser,
  loginError,
  loginFailed,
  userInfo,
} from '../../components/auth/store/auth.selectors';
import { selectAnnotationModule, selectGlobalToast, selectTheme } from '../../store/app.selectors';
import { AnnotationActions } from '../../components/annotation/store/action-types';
import {
  isToolSelected,
  selectBufferRegion,
  selectCurrentWaveProgress,
  selectIsSegmentDeletionModalState,
  selectIsWaveLoaded,
  selectIsWaveLoading,
  selectNotifications,
  selectNotificationsLoading,
  selectRequestReviewAdminList,
  selectRequestReviewAdminListLoading,
  selectReviewNote,
  selectSegmentDeleteLoading,
  selectSegmentNavigationLoading,
  selectSegmentSaveLoading,
  selectSelectedNotification,
  selectSelectedRegion,
  selectSelectedReviewerId,
  selectSelectedSpeaker,
  selectSelectedTool,
  selectSpeakersSuggestions,
  selectSpeakerSuggestionLoading,
  selectTranscriptData,
  selectTranscriptDirection,
  selectTranscriptLoading,
  selectTranscriptStatsData,
  selectTranscriptStatsLoading,
} from '../../components/annotation/store/annotation.selectors';
import {
  DynamicDetailsInterface,
  DynamicTableInterface,
  DynamicTableRequestInterface,
  FileInterface,
  GetFilesListByUserQueryInterface,
  NotificationResponseInterface,
  PaginationQueryInterface,
  ProjectInterface,
  SegmentInterface,
  SignInResponseInterface,
  ToastInterface,
  ToolItem,
  TranscriptIdInterface,
  TranscriptResponseInterface,
  UploadTranscriptFileInterface,
  UserInterface,
  WavePlayerParamsEncodedInterface,
} from '@interfaces';
import { isNonNull } from '../../utils';
import { setGlobalToast, setTheme } from '../../store/app.actions';
import { ToolboxActions } from '../../components/toolbox/store/action-types';
import {
  selectFileIdQueryParam,
  selectFilesList,
  selectFileUploadProgress,
  selectHorizontalZoom,
  selectIsFilesListLoading,
  selectIsFilesLoaded,
  selectIsUsersListLoaded,
  selectIsUsersListLoading,
  selectSelectedFile,
  selectSelectedFileId,
  selectSelectedFileStatus,
  selectSelectedUser,
  selectSelectedUserId,
  selectUserIdQueryParam,
  selectUsersList,
  selectVerticalZoom,
} from '../../components/toolbox/store/toolbox.selectors';
import { AnnotationModulesEnum, FileStatusEnum, SegmentDirectionEnum, TranscriptionDirectionEnum } from '@enums';
import { DashboardSelectors } from '../../components/dashboard/store/selector-types';
import { DashboardActions } from '../../components/dashboard/store/action-types';

import { FilesDashboardActions } from '../../components/files/store/action-types';
import { FilesDashboardSelectors } from '../../components/files/store/selector-types';
import { setAdminList } from '../../components/annotation/store/annotation.actions';
import { ProjectsDashboardActions } from 'src/app/components/projects/store/actions-types';
import { ProjectsDashboardSelectors } from 'src/app/components/projects/store/selectors-types';
import { BufferRegionInterface, SelectedSegmentState } from 'src/app/components/annotation/store/annotation.state';
import { UserDashboardSelectors } from '../../components/users/store/selector-types';
import { UserDashboardAction } from '../../components/users/store/action-types';
import {
  ISetSelectedRegionActionConfig,
  ISetSelectedUserActionConfig,
} from '../../interfaces/actions-config/annotation';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  constructor(private store: Store) {}

  // =================== auth =================== //

  login(): void {
    this.store.dispatch(AuthActions.login());
  }

  loginSuccess(user: SignInResponseInterface): void {
    this.store.dispatch(AuthActions.loginSuccess({ user }));
  }

  loginFail(error: string): void {
    this.store.dispatch(AuthActions.loginFail({ error }));
  }

  logout(): void {
    this.store.dispatch(AuthActions.logout());
  }

  setUserInfo(userInfo: UserInterface): void {
    this.store.dispatch(AuthActions.setUserInfo({ userInfo }));
  }

  getLoginLoading(): Observable<boolean> {
    return this.store.select(isLoginLoading);
  }

  getIsLoggedIn(): Observable<boolean> {
    return this.store.select(isLoggedIn);
  }

  getLoginFailed(): Observable<boolean> {
    return this.store.select(loginFailed);
  }

  getLoginError(): Observable<string> {
    return this.store.select(loginError);
  }

  getCurrentUser(): Observable<SignInResponseInterface> {
    return this.store.select(currentUser).pipe(filter(isNonNull));
  }

  getCurrentUserInfo(): Observable<UserInterface | undefined> {
    return this.store.select(userInfo);
  }

  getCurrentAnnotationModule(): Observable<AnnotationModulesEnum> {
    return this.store.select(selectAnnotationModule);
  }

  getIsUser(): Observable<boolean> {
    return this.store.select(isUser);
  }

  getIsAdmin(): Observable<boolean> {
    return this.store.select(isAdmin);
  }

  // ================= Global Toast ================== //

  setGlobalToast(toast: ToastInterface): void {
    this.store.dispatch(setGlobalToast({ toast }));
  }

  getGlobalToast(): Observable<ToastInterface> {
    return this.store.select(selectGlobalToast);
  }

  // ================= Theme ================== //

  setTheme(selectedTheme: string): void {
    this.store.dispatch(setTheme({ selectedTheme }));
  }

  getTheme(): Observable<string> {
    return this.store.select(selectTheme);
  }

  // =============== Toolbar ================= //

  setSelectedTool(selectedTool: ToolItem): void {
    this.store.dispatch(
      AnnotationActions.setSelectedTool({
        tool: !!selectedTool ? { name: selectedTool.name, title: selectedTool.title } : null,
      })
    );
  }

  isToolSelected(): Observable<boolean> {
    return this.store.select(isToolSelected);
  }

  getSelectedTool(): Observable<ToolItem> {
    return this.store.select(selectSelectedTool);
  }

  //================== Annotation Transcript ===================//

  loadTranscriptionStats(payload: TranscriptIdInterface): void {
    this.store.dispatch(AnnotationActions.loadTranscriptStats(payload));
  }
  setTranscriptionStats(details: DynamicDetailsInterface): void {
    this.store.dispatch(AnnotationActions.setTranscriptStats({ details }));
  }

  loadTranscriptionData(payload: TranscriptIdInterface): void {
    this.store.dispatch(AnnotationActions.loadTranscriptData(payload));
  }

  getTranscriptData(): Observable<TranscriptResponseInterface> {
    return this.store.select(selectTranscriptData);
  }

  setTranscriptionData(transcriptData: TranscriptResponseInterface): void {
    this.store.dispatch(AnnotationActions.setTranscriptData({ transcriptData }));
  }

  getTranscriptLoading(): Observable<boolean> {
    return this.store.select(selectTranscriptLoading);
  }

  setTranscriptLoading(loading: boolean): void {
    this.store.dispatch(AnnotationActions.setTranscriptLoading({ loading }));
  }

  getSelectedRegion(): Observable<SelectedSegmentState> {
    return this.store.select(selectSelectedRegion);
  }

  navigateSegmentNeighbor(navigation: SegmentDirectionEnum) {
    this.store.dispatch(AnnotationActions.navigateSegmentNeighbor({ navigation }));
  }

  setSelectedRegion(
    selectedRegion: SegmentInterface,
    config: ISetSelectedRegionActionConfig = { manual: false }
  ): void {
    this.store.dispatch(AnnotationActions.setSelectedRegion({ selectedRegion, config }));
  }

  getCurrentWaveProgress(): Observable<number> {
    return this.store.select(selectCurrentWaveProgress);
  }

  setCurrentWaveProgress(progress: number): void {
    this.store.dispatch(AnnotationActions.setCurrentWaveProgress({ progress }));
  }

  getBufferRegion(): Observable<BufferRegionInterface> {
    return this.store.select(selectBufferRegion);
  }

  setBufferRegionParams(params: Partial<BufferRegionInterface>): void {
    this.store.dispatch(AnnotationActions.setBufferRegionParams({ params }));
  }

  setSelectedRegionParams(selectedRegionParams: Partial<SegmentInterface>): void {
    this.store.dispatch(AnnotationActions.setSelectedRegionParams({ selectedRegionParams }));
  }

  setTranscriptionStatsLoading(isLoading: boolean): void {
    this.store.dispatch(AnnotationActions.setTranscriptStatsLoading({ isLoading }));
  }

  getTranscriptionStats(): Observable<DynamicDetailsInterface> {
    return this.store.select(selectTranscriptStatsData);
  }

  getTranscriptionStatsLoading(): Observable<boolean> {
    return this.store.select(selectTranscriptStatsLoading);
  }

  setTranscriptDirection(direction: TranscriptionDirectionEnum): void {
    this.store.dispatch(AnnotationActions.setTranscriptDirection({ direction }));
  }

  getTranscriptDirection(): Observable<TranscriptionDirectionEnum> {
    return this.store.select(selectTranscriptDirection);
  }

  loadSpeakersSuggestions(query: string): void {
    this.store.dispatch(AnnotationActions.loadSpeakerSuggestions({ query }));
  }

  setSpeakerSuggestions(suggestions: string[]): void {
    this.store.dispatch(AnnotationActions.setSpeakersSuggestion({ suggestions }));
  }

  getSpeakersSuggestions(): Observable<string[]> {
    return this.store.select(selectSpeakersSuggestions);
  }

  setSelectedSpeaker(selectedSpeaker: string) {
    this.store.dispatch(AnnotationActions.setSelectedSpeaker({ speaker: selectedSpeaker }));
  }

  getSelectedSpeaker(): Observable<string> {
    return this.store.select(selectSelectedSpeaker);
  }

  setSpeakerSuggestionLoading(isLoading: boolean): void {
    this.store.dispatch(AnnotationActions.setSpeakerSuggestionLoading({ isLoading }));
  }

  getSpeakerSuggestionLoading(): Observable<boolean> {
    return this.store.select(selectSpeakerSuggestionLoading);
  }

  //====================== Annotation Segments ====================//

  deleteSegment(segmentId: string): void {
    this.store.dispatch(AnnotationActions.deleteSegment({ segmentId }));
  }

  saveSegment(segment: SegmentInterface): void {
    this.store.dispatch(AnnotationActions.saveSegment({ segment }));
  }

  saveAllSegments(segmentsData: TranscriptResponseInterface) {
    this.store.dispatch(AnnotationActions.saveAllSegments({ segmentsData }));
  }

  setSegmentSaveLoading(loading: boolean): void {
    this.store.dispatch(AnnotationActions.setSegmentSaveLoading({ loading }));
  }

  getSegmentSaveLoading(): Observable<boolean> {
    return this.store.select(selectSegmentSaveLoading);
  }

  setSegmentDeleteLoading(loading: boolean): void {
    this.store.dispatch(AnnotationActions.setSegmentDeleteLoading({ loading }));
  }

  getSegmentDeleteLoading(): Observable<boolean> {
    return this.store.select(selectSegmentDeleteLoading);
  }

  setSegmentDeletionModalState(isOpen: boolean) {
    this.store.dispatch(AnnotationActions.setSegmentDeletionModalState({ isOpen }));
  }

  getSegmentDeletionModalState(): Observable<boolean> {
    return this.store.select(selectIsSegmentDeletionModalState);
  }

  setSegmentNavigationLoading(loading: boolean): void {
    this.store.dispatch(AnnotationActions.setSegmentNavigationLoading({ loading }));
  }

  getSegmentNavigationLoading(): Observable<boolean> {
    return this.store.select(selectSegmentNavigationLoading);
  }

  //================== Annotation Wave ===================//

  setWaveFile(fileId: string): void {
    this.store.dispatch(AnnotationActions.loadWave({ fileId }));
  }

  setWaveParams(waveParams: WavePlayerParamsEncodedInterface): void {
    this.store.dispatch(AnnotationActions.setWaveParams({ waveParams }));
  }

  setWaveLoading(isLoading: boolean): void {
    this.store.dispatch(AnnotationActions.setWaveLoading({ isLoading }));
  }

  getWaveLoading(): Observable<boolean> {
    return this.store.select(selectIsWaveLoading);
  }

  setWaveLoaded(isLoaded: boolean): void {
    this.store.dispatch(AnnotationActions.setWaveLoaded({ isLoaded }));
  }

  getWaveLoaded(): Observable<boolean> {
    return this.store.select(selectIsWaveLoaded);
  }

  // =============== Request Review =============== //

  loadRequestReviewAdminList(): void {
    this.store.dispatch(AnnotationActions.loadAdminList());
  }

  setRequestReviewAdminList(adminList: UserInterface[]): void {
    this.store.dispatch(setAdminList({ adminList }));
  }

  getRequestReviewAdminList(): Observable<UserInterface[]> {
    return this.store.select(selectRequestReviewAdminList);
  }

  setRequestReviewAdminListLoading(loading: boolean): void {
    this.store.dispatch(AnnotationActions.setAdminListLoading({ loading }));
  }

  getRequestReviewAdminListLoading(): Observable<boolean> {
    return this.store.select(selectRequestReviewAdminListLoading);
  }

  setSelectedReviewerId(reviewerId: string): void {
    this.store.dispatch(AnnotationActions.setSelectedReviewerId({ reviewerId }));
  }
  getSelectedReviewerId(): Observable<string> {
    return this.store.select(selectSelectedReviewerId);
  }

  setReviewNote(reviewNote: string): void {
    this.store.dispatch(AnnotationActions.setReviewNote({ reviewNote }));
  }

  getReviewNote(): Observable<string> {
    return this.store.select(selectReviewNote);
  }

  pushReviewNotification(): void {
    this.store.dispatch(AnnotationActions.pushNotification());
  }

  // ==================== Review Notifications ================ //

  setNotifications(notifications: NotificationResponseInterface[]): void {
    this.store.dispatch(AnnotationActions.setNotificationsList({ notifications }));
  }

  getNotifications(): Observable<NotificationResponseInterface[]> {
    return this.store.select(selectNotifications);
  }

  setNotificationsLoading(loading: boolean): void {
    this.store.dispatch(AnnotationActions.setNotificationsLoading({ loading }));
  }

  getNotificationsLoading(): Observable<boolean> {
    return this.store.select(selectNotificationsLoading);
  }

  setSelectedNotification(notification: NotificationResponseInterface): void {
    this.store.dispatch(AnnotationActions.setSelectedNotification({ notification }));
  }

  getSelectNotifications(): Observable<NotificationResponseInterface> {
    return this.store.select(selectSelectedNotification);
  }

  loadMoreNotifications(): void {
    this.store.dispatch(AnnotationActions.loadMoreNotification());
  }

  // ===================== Toolbox ====================== //

  setToolboxFilesList(files: FileInterface[]): void {
    return this.store.dispatch(ToolboxActions.setFilesList({ files }));
  }
  getToolboxFilesList(): Observable<FileInterface[]> {
    return this.store.select(selectFilesList);
  }

  setToolboxFilesListLoading(isLoading: boolean): void {
    return this.store.dispatch(ToolboxActions.setIsFilesLoading({ isLoading }));
  }
  getToolboxFilesListLoading(): Observable<boolean> {
    return this.store.select(selectIsFilesListLoading);
  }

  setToolboxFilesLoaded(loaded: boolean): void {
    return this.store.dispatch(ToolboxActions.setIsFilesLoaded({ isFilesLoaded: loaded }));
  }
  getToolboxFilesLoaded(): Observable<boolean> {
    return this.store.select(selectIsFilesLoaded);
  }

  setToolboxUsersList(users: UserInterface[]): void {
    return this.store.dispatch(ToolboxActions.setUsersList({ users }));
  }
  getToolboxUsersList(): Observable<UserInterface[]> {
    return this.store.select(selectUsersList);
  }

  setToolboxSelectedFile(file: FileInterface | null): void {
    return this.store.dispatch(ToolboxActions.setSelectedFile({ file }));
  }
  getToolboxSelectedFile(): Observable<FileInterface | null> {
    return this.store.select(selectSelectedFile);
  }
  getToolboxSelectedFileId(): Observable<string> {
    return this.store.select(selectSelectedFileId);
  }

  setToolboxUsersListLoading(isLoading: boolean): void {
    return this.store.dispatch(ToolboxActions.setIsUsersLoading({ isLoading }));
  }
  getToolboxUsersListLoading(): Observable<boolean> {
    return this.store.select(selectIsUsersListLoading);
  }

  setToolboxUsersListLoaded(isLoaded: boolean): void {
    return this.store.dispatch(ToolboxActions.setIsUsersLoaded({ isUsersLoaded: isLoaded }));
  }
  getToolboxUsersListLoaded(): Observable<boolean> {
    return this.store.select(selectIsUsersListLoaded);
  }

  setToolboxSelectedUser(user: UserInterface, config?: ISetSelectedUserActionConfig): void {
    return this.store.dispatch(ToolboxActions.setSelectedUser({ user, config }));
  }
  getToolboxSelectedUser(): Observable<UserInterface | null> {
    return this.store.select(selectSelectedUser);
  }

  getToolboxSelectedUserId(): Observable<string> {
    return this.store.select(selectSelectedUserId);
  }

  deleteSegmentById(segmentId: string): void {
    this.store.dispatch(ToolboxActions.deleteSegment({ segmentId }));
  }

  uploadTranscriptFile(transcriptFileData: UploadTranscriptFileInterface) {
    this.store.dispatch(ToolboxActions.uploadTranscriptFileRequest({ transcriptFileData }));
  }

  getFileUploadProgress(): Observable<number> {
    return this.store.select(selectFileUploadProgress);
  }

  downloadTranscriptFile(fileFormat: string) {
    this.store.dispatch(ToolboxActions.downloadTranscriptFile({ fileFormat }));
  }
  // ============== Common Toolbox ================ //
  setHorizontalZoom(value: number): void {
    this.store.dispatch(ToolboxActions.setHorizontalZoom({ value }));
  }

  getHorizontalZoom(): Observable<number> {
    return this.store.select(selectHorizontalZoom);
  }

  setVerticalZoom(value: number): void {
    this.store.dispatch(ToolboxActions.setVerticalZoom({ value }));
  }

  getVerticalZoom(): Observable<number> {
    return this.store.select(selectVerticalZoom);
  }

  setSelectedFileStatus(status: FileStatusEnum) {
    this.store.dispatch(ToolboxActions.setFileStatus({ fileStatus: status }));
  }

  getSelectedFileStatus(): Observable<FileStatusEnum> {
    return this.store.select(selectSelectedFileStatus);
  }

  // =============== Dashboard ================ //

  loadDashboardFilesCard(): void {
    this.store.dispatch(DashboardActions.loadFiles());
  }

  loadDashboardUsersCard(): void {
    this.store.dispatch(DashboardActions.loadUsers());
  }

  loadDashboardProjectsCard(): void {
    this.store.dispatch(DashboardActions.loadProjects());
  }

  getFilesCount(): Observable<number> {
    return this.store.select(DashboardSelectors.selectFilesCount);
  }

  setFilesCount(count: number): void {
    this.store.dispatch(DashboardActions.setFilesCount({ count }));
  }

  getDashboardFilesCardLoading(): Observable<boolean> {
    return this.store.select(DashboardSelectors.selectFilesLoading);
  }

  setDashboardFilesCardLoading(loading: boolean) {
    this.store.dispatch(DashboardActions.setFilesLoading({ loading }));
  }

  setDashboardFilesUploadList(filesUploadList: File[]): void {
    this.store.dispatch(DashboardActions.setFilesUploadList({ filesUploadList }));
  }

  getDashboardFilesUploadList(): Observable<File[]> {
    return this.store.select(DashboardSelectors.selectFilesUploadList);
  }

  setDashboardFilesUploadProgress(filesUploadProgress: Record<string, number>): void {
    this.store.dispatch(DashboardActions.setFilesUploadProgress({ filesUploadProgress }));
  }

  getDashboardFilesUploadProgress(): Observable<Record<string, number>> {
    return this.store.select(DashboardSelectors.selectFilesUploadProgress);
  }

  setDashboardFilesUploadCompleted(completed: boolean): void {
    this.store.dispatch(DashboardActions.setFilesUploadComplete({ completed }));
  }

  getDashboardFilesUploadCompleted(): Observable<boolean> {
    return this.store.select(DashboardSelectors.selectFilesUploadCompleted);
  }

  getProjectsCount(): Observable<number> {
    return this.store.select(DashboardSelectors.selectProjectsCount);
  }

  setProjectsCount(count: number): void {
    this.store.dispatch(DashboardActions.setProjectsCount({ count }));
  }

  getDashboardProjectsCardLoading(): Observable<boolean> {
    return this.store.select(DashboardSelectors.selectProjectsLoading);
  }

  setDashboardProjectsCardLoading(loading: boolean) {
    this.store.dispatch(DashboardActions.setProjectsLoading({ loading }));
  }

  getUsersCount(): Observable<number> {
    return this.store.select(DashboardSelectors.selectUsersCount);
  }

  setUsersCount(count: number): void {
    this.store.dispatch(DashboardActions.setUsersCount({ count }));
  }

  getDashboardUsersCardLoading(): Observable<boolean> {
    return this.store.select(DashboardSelectors.selectUsersLoading);
  }

  setDashboardUsersCardLoading(loading: boolean) {
    this.store.dispatch(DashboardActions.setUsersLoading({ loading }));
  }

  loadAnnotatorFilesStats() {
    this.store.dispatch(DashboardActions.loadAnnotatorFilesStats());
  }

  getAnnotatorFilesStatsLoading() {
    return this.store.select(DashboardSelectors.selectAnnotatorFilesStatsLoading);
  }

  setAnnotatorFilesStatsLoading(isLoading: boolean) {
    this.store.dispatch(DashboardActions.setAnnotatorFilesStatsLoading({ isLoading }));
  }

  getAnnotatorFilesStats() {
    return this.store.select(DashboardSelectors.selectAnnotatorFilesStats);
  }

  getAssignedFilesListByUserId() {
    return this.store.select(DashboardSelectors.selectAssignedFilesListByUser);
  }

  loadFilesByUserId(query: GetFilesListByUserQueryInterface) {
    this.store.dispatch(DashboardActions.loadAssignedFilesListByUserId({ query }));
  }

  setFilesByUserIdLoading(isLoading: boolean) {
    this.store.dispatch(DashboardActions.setAssignedFilesListByUserIdLoading({ isLoading }));
  }

  getFilesByUserIdLoading() {
    return this.store.select(DashboardSelectors.selectAssignedFilesListByUserLoading);
  }
  // ========= Admin dashboard files ========= //
  loadFilesDashboard(filesAssignmentRequest: DynamicTableRequestInterface): void {
    this.store.dispatch(FilesDashboardActions.loadFiles({ filesAssignmentRequest }));
  }

  loadFilesDashboardProjects(): void {
    this.store.dispatch(FilesDashboardActions.loadProjects());
  }

  loadFilesDashboardUsers(): void {
    this.store.dispatch(FilesDashboardActions.loadUsers());
  }

  setFilesDashboardList(files: DynamicTableInterface): void {
    this.store.dispatch(FilesDashboardActions.setFilesList({ files }));
  }

  getFilesDashboardList(): Observable<DynamicTableInterface> {
    return this.store.select(FilesDashboardSelectors.selectFilesList);
  }

  setFilesDashboardLoading(loading: boolean): void {
    this.store.dispatch(FilesDashboardActions.setFilesLoading({ loading }));
  }

  getFilesDashboardLoading(): Observable<boolean> {
    return this.store.select(FilesDashboardSelectors.selectFilesLoading);
  }

  setFilesDashboardProjectsList(projectList: ProjectInterface[]): void {
    this.store.dispatch(FilesDashboardActions.setProjectsList({ projectList }));
  }

  getFilesDashboardProjectsList(): Observable<ProjectInterface[]> {
    return this.store.select(FilesDashboardSelectors.selectProjectsList);
  }

  setFilesDashboardProjectsLoading(isProjectsListLoading: boolean): void {
    this.store.dispatch(FilesDashboardActions.setProjectsLoading({ isProjectsListLoading }));
  }
  getFilesDashboardProjectsLoading(): Observable<boolean> {
    return this.store.select(FilesDashboardSelectors.selectProjectsLoading);
  }

  setFilesDashboardUsersList(usersList: UserInterface[]): void {
    this.store.dispatch(FilesDashboardActions.setUsersList({ usersList }));
  }

  getFilesDashboardUsersList(): Observable<UserInterface[]> {
    return this.store.select(FilesDashboardSelectors.selectUsersList);
  }

  setFilesDashboardUsersLoading(isUsersListLoading: boolean): void {
    this.store.dispatch(FilesDashboardActions.setUsersLoading({ isUsersListLoading }));
  }
  getFilesDashboardUsersLoading(): Observable<boolean> {
    return this.store.select(FilesDashboardSelectors.selectUsersLoading);
  }

  // ========= Admin dashboard projects list ========= //

  loadProjectsDashboard(pagination: PaginationQueryInterface): void {
    this.store.dispatch(ProjectsDashboardActions.loadProjects({ pagination }));
  }

  setProjectsDashboardList(projectsList: DynamicTableInterface): void {
    this.store.dispatch(ProjectsDashboardActions.setProjectsList({ projectsList }));
  }

  getProjectsDashboardList(): Observable<DynamicTableInterface> {
    return this.store.select(ProjectsDashboardSelectors.selectProjectsList);
  }

  setProjectsDashboardLoading(isLoading: boolean): void {
    this.store.dispatch(ProjectsDashboardActions.setProjectsLisLoading({ isLoading }));
  }

  getProjectsDashboardLoading(): Observable<boolean> {
    return this.store.select(ProjectsDashboardSelectors.selectProjectsLoading);
  }

  getAnnotatorFilesStatsFailed(): Observable<boolean> {
    return this.store.select(DashboardSelectors.selectAnnotatorFilesStatsFailed);
  }

  // =============== Admin Dashboard Users List ============ //

  loadUsersDashboardList(request: DynamicTableRequestInterface): void {
    this.store.dispatch(UserDashboardAction.loadUsers({ request }));
  }

  setUsersDashboardList(usersList: DynamicTableInterface): void {
    this.store.dispatch(UserDashboardAction.setUsers({ usersList }));
  }

  setUsersDashboardLoading(loading: boolean): void {
    this.store.dispatch(UserDashboardAction.setUsersLoading({ loading }));
  }

  getUsersDashboardLoading(): Observable<boolean> {
    return this.store.select(UserDashboardSelectors.selectUsersLoading);
  }

  getUsersDashboardList(): Observable<DynamicTableInterface> {
    return this.store.select(UserDashboardSelectors.selectUsersList);
  }

  // ============= Router Query Params Selectors =============== //
  getFileIdQueryParam(): Observable<string> {
    return this.store.select(selectFileIdQueryParam);
  }

  getUserIdQueryParam(): Observable<string> {
    return this.store.select(selectUserIdQueryParam);
  }
}
