<div
  class="outline-base-content overflow-hidden rounded-lg outline-2 outline-offset-2 outline"
  [attr.data-set-theme]="theme"
  [ngClass]="{ outline: selectedTheme === theme }"
  (click)="onSelectTheme()"
>
  <div [attr.data-theme]="theme" class="bg-base-100 text-base-content cursor-pointer font-sans">
    <div class="grid grid-cols-5 grid-rows-3">
      <div class="col-span-5 row-span-3 flex gap-1 py-3 px-4">
        <div class="flex-grow text-sm font-bold">{{ theme }}</div>
        <div class="flex flex-shrink-0 flex-wrap gap-1">
          <div class="bg-primary w-2 rounded"></div>
          <div class="bg-secondary w-2 rounded"></div>
          <div class="bg-accent w-2 rounded"></div>
          <div class="bg-neutral w-2 rounded"></div>
        </div>
      </div>
    </div>
  </div>
</div>
