import { SortDirectionEnum } from 'src/app/enums/shared/sort-order.enum';

export enum DynamicTableTypesEnum {
  id = 'id',
  string = 'string',
  number = 'number',
  date = 'date',
  time = 'time',
  fileSize = 'fileSize',
  action = 'action',
}

export interface DynamicTableHeaderInterface {
  key: string;
  name: string;
  type: DynamicTableTypesEnum;
  sortable: boolean;
}

// TODO: DynamicTableInterface should take generic type
export interface DynamicTableInterface {
  headers: DynamicTableHeaderInterface[];
  data: DynamicTableData;
  totalRows: number;
  limit?: number;
  offset?: number;
}

export type DynamicTableData = Record<string, string | number | Date>[];

export interface DynamicTableSortDetailsInterface {
  column: string;
  direction: SortDirectionEnum;
}

export interface DynamicTableRequestInterface {
  limit?: number;
  offset?: number;
  column?: string;
  direction?: SortDirectionEnum;
}
