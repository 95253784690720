import { TranscriptIdInterface } from './transcription.interface';

export interface INotificationSender {
  email: string;
  first_name: string;
  last_name: string;
  registration_date?: string;
  id: string;
}

export interface NotificationRequestInterface {
  senderId: string;
  receiverId: string;
  sender?: INotificationSender;
  transcriptId: TranscriptIdInterface;
  reviewNote?: string;
  segmentId: string;
}

export interface NotificationResponseInterface extends NotificationRequestInterface {
  id: string;
  status: string;
  reportTime: string;
}

export interface NotificationStreamInterface {
  notifications: NotificationResponseInterface[];
}

export enum NotificationStatusEnum {
  seen = 'seen',
  delivered = 'delivered',
  sent = 'sent',
}
