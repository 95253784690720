import { Injectable } from '@angular/core';
import { SignInResponseInterface } from '../../../interfaces/auth/sign-in.interface';
import { LOCAL_STORAGE_KEYS } from '../../../constants/shared/local-storage.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthWorkerService {
  constructor() {}

  storeCurrentUser({ id, token }: SignInResponseInterface) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_USER_ID, id);
    localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_USER_TOKEN, token);
  }

  clearCurrentUser(): void {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.CURRENT_USER_ID);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.CURRENT_USER_TOKEN);
  }

  getCurrentUser(): SignInResponseInterface | null {
    const id = this.getCurrentUserId();
    const token = this.getAccessToken();
    return id && token ? { id, token } : null;
  }

  getAccessToken(): string | null {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_USER_TOKEN);
  }

  getCurrentUserId(): string | null {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_USER_ID);
  }
}
