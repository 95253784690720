import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastTypesEnum } from '../../../enums/shared/toast-types.enum';

@Component({
  selector: 'at-custom-toast',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomToastComponent {
  @Input() message: string;
  @Input() type: ToastTypesEnum;

  @Output() closed = new EventEmitter();

  ToastTypesEnum = ToastTypesEnum;

  closeToast(): void {
    this.closed.emit();
  }
}
