import {
  faArrowsLeftRightToLine,
  faDownload,
  faToolbox,
  faSwatchbook,
  faHeadphonesSimple,
  faCameraRetro,
  faFileAudio,
  faFloppyDisk,
} from '@fortawesome/pro-duotone-svg-icons';

export const DUO_TONE_ICONS = [
  faToolbox,
  faDownload,
  faArrowsLeftRightToLine,
  faSwatchbook,
  faHeadphonesSimple,
  faCameraRetro,
  faFileAudio,
  faFloppyDisk,
];
