import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NotificationResponseInterface, NotificationStatusEnum } from '@interfaces';

@Component({
  selector: 'at-notification-list-item',
  templateUrl: './notification-list-item.component.html',
  styleUrls: ['./notification-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListItemComponent implements OnChanges {
  @Input() notification: NotificationResponseInterface;
  @Input() selectedNotification: NotificationResponseInterface;
  @Output() notificationSelect = new EventEmitter<NotificationResponseInterface>();

  senderNameInitials: string;

  NOTIFICATION_STATUS_ENUM = NotificationStatusEnum;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.notification && changes.notification.currentValue) {
      this.onNotificationInputChange();
    }
  }

  onSelectNotification(e: MouseEvent): void {
    e.stopImmediatePropagation();
    this.notificationSelect.emit(this.notification);
  }

  private onNotificationInputChange(): void {
    const {
      sender: { first_name, last_name },
    } = this.notification;

    this.senderNameInitials = `${first_name[0]}${last_name[0]}`;
  }
}
