import { createAction, props } from '@ngrx/store';
import { DynamicTableInterface, DynamicTableRequestInterface } from '@interfaces';
import { FilesDashboardEnum } from '@enums';
import { ProjectInterface, UserInterface } from '@interfaces';

export const loadFiles = createAction(
  FilesDashboardEnum.LOAD_FILES,
  props<{ filesAssignmentRequest: DynamicTableRequestInterface }>()
);
export const setFilesList = createAction(FilesDashboardEnum.SET_FILES_LIST, props<{ files: DynamicTableInterface }>());
export const setFilesLoading = createAction(FilesDashboardEnum.SET_FILES_LOADING, props<{ loading: boolean }>());

export const loadProjects = createAction(FilesDashboardEnum.LOAD_PROJECTS);
export const setProjectsList = createAction(
  FilesDashboardEnum.SET_PROJECTS,
  props<{ projectList: ProjectInterface[] }>()
);
export const setProjectsLoading = createAction(
  FilesDashboardEnum.SET_PROJECTS_LOADING,
  props<{ isProjectsListLoading: boolean }>()
);
export const loadUsers = createAction(FilesDashboardEnum.LOAD_USERS);
export const setUsersList = createAction(FilesDashboardEnum.SET_USERS, props<{ usersList: UserInterface[] }>());
export const setUsersLoading = createAction(
  FilesDashboardEnum.SET_USERS_LOADING,
  props<{ isUsersListLoading: boolean }>()
);
