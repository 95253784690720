import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserInterface } from '@interfaces';

@Component({
  selector: 'at-custom-avatar',
  templateUrl: './custom-avatar.component.html',
  styleUrls: ['./custom-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomAvatarComponent {
  @Input() text: string;
  @Input() user: Partial<UserInterface>;
  @Input() selected: boolean;
  @Input() isAdmin: boolean;
}
