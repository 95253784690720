import { FilesDashboardState } from './files.state';
import { createReducer, on } from '@ngrx/store';
import { FilesDashboardActions } from './action-types';

export const filesDashboardFeatureKey = 'filesDashboard';
export const filesDashboardInitialState: FilesDashboardState = {
  filesList: null,
  loading: false,
  projects: null,
  users: null,
};

export const filesDashboardReducer = createReducer(
  filesDashboardInitialState,
  on(
    FilesDashboardActions.setFilesList,
    (state, { files }): FilesDashboardState => ({
      ...state,
      filesList: files,
    })
  ),
  on(
    FilesDashboardActions.setFilesLoading,
    (state, { loading }): FilesDashboardState => ({
      ...state,
      loading,
    })
  ),
  on(
    FilesDashboardActions.setProjectsList,
    (state, { projectList }): FilesDashboardState => ({ ...state, projects: { ...state.projects, projectList } })
  ),
  on(
    FilesDashboardActions.setProjectsLoading,
    (state, { isProjectsListLoading: loading }): FilesDashboardState => ({
      ...state,
      projects: { ...state.projects, loading },
    })
  ),
  on(
    FilesDashboardActions.setUsersList,
    (state, { usersList }): FilesDashboardState => ({ ...state, users: { ...state.users, usersList } })
  ),
  on(
    FilesDashboardActions.setUsersLoading,
    (state, { isUsersListLoading: loading }): FilesDashboardState => ({
      ...state,
      users: { ...state.users, loading },
    })
  )
);
