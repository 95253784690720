import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'at-theme-selection-item',
  templateUrl: './theme-selection-item.component.html',
  styleUrls: ['./theme-selection-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeSelectionItemComponent {
  @Input() theme: string;
  @Input() selectedTheme: string;
  @Output() themeSelected = new EventEmitter<string>();

  onSelectTheme() {
    this.themeSelected.emit(this.theme);
  }
}
