export enum RouteEnum {
  signIn = 'sign-in',
  home = 'home',
  files = 'files',
  users = 'users',
  projects = 'projects',
  annotation = 'annotation',
  dashboard = 'dashboard',
}

export enum AnnotationModulesEnum {
  AUDIO = 'audio',
  IMAGE = 'image',
  DEFAULT = '',
}
