export enum ActionTypesEnum {
  UPLOAD_REQUEST = '[Annotation Save Toolbox] upload Transcript File Request',
  UPLOAD_STATUS = '[Annotation Save Toolbox] upload Transcript File Status',
  UPLOAD_CANCEL = '[Annotation Save Toolbox] upload Transcript File Cancel',
  DOWNLOAD_REQUEST = '[Annotation Save Toolbox] Download Transcript File Request',
  DOWNLOAD_COMPLETED = '[Annotation Save Toolbox] Download Transcript File Completed',
}

export enum UploadStatusEnum {
  READY = 'Ready',
  REQUESTED = 'Requested',
  STARTED = 'Started',
  FAILED = 'Failed',
  COMPLETED = 'Completed',
}

export enum DownloadStatusEnum {
  PENDING = 'Pending',
  IN_PROGRESS = 'In progress',
  DONE = 'Done',
}
