export enum ColorCodeEnum {
  primary = 'p',
  primaryFocus = 'pf',
  primaryContent = 'pc',
  secondary = 's',
  secondaryFocus = 'sf',
  secondaryContent = 'sc',
  accent = 'a',
  accentFocus = 'af',
  accentContent = 'ac',
  info = 'in',
  infoContent = 'inc',
  success = 'su',
  successContent = 'suc',
  warn = 'wa',
  warnContent = 'wac',
  error = 'er',
  errorContent = 'erc',
}
