import { DownloadStatusEnum, UploadStatusEnum } from 'src/app/enums/annotation/annotation.toolbox.enum';
import { FileInterface, UserInterface } from '@interfaces';
import { FileStatusEnum } from '@enums';

export interface FilesToolState {
  files: FileInterface[];
  isFilesListLoading: boolean;
  isFilesListLoaded: boolean;
  selectedFile: FileInterface | null;
  users: UserInterface[];
  isUsersListLoading: boolean;
  isUsersListLoaded: boolean;
  selectedUser: UserInterface | null;
}

export interface SaveToolState {
  importFile: {
    status: UploadStatusEnum;
    error: string | null;
    progress: number | null;
  };
  downloadFile: {
    status: DownloadStatusEnum;
    error: string | null;
    progress: number | null;
  };
}
export interface CommonToolsState {
  horizontalZoom: number;
  verticalZoom: number;
  selectedFileStatus: FileStatusEnum;
}

export interface ToolboxState {
  filesTool: FilesToolState;
  saveTool: SaveToolState;
  commonTools: CommonToolsState;
}

export const initialToolboxState: ToolboxState = {
  filesTool: {
    files: [],
    isFilesListLoading: false,
    isFilesListLoaded: false,
    selectedFile: null,
    users: [],
    isUsersListLoading: false,
    isUsersListLoaded: false,
    selectedUser: null,
  },
  saveTool: {
    importFile: {
      status: UploadStatusEnum.READY,
      error: null,
      progress: null,
    },
    downloadFile: {
      status: DownloadStatusEnum.PENDING,
      error: null,
      progress: null,
    },
  },
  commonTools: {
    horizontalZoom: 50,
    verticalZoom: 1,
    selectedFileStatus: null,
  },
};
