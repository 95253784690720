<ng-container [ngSwitch]="menuItemType">
  <ng-container *ngSwitchCase="MENU_ITEM_TYPE.theme">
    <div class="grid grid-cols-1 gap-3 p-3" tabindex="0">
      <at-theme-selection-item
        *ngFor="let theme of itemsData"
        [theme]="theme"
        [selectedTheme]="selectedTheme$ | async"
        (themeSelected)="onSelectTheme($event)"
      ></at-theme-selection-item>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="MENU_ITEM_TYPE.notification">
    <div class="grid grid-cols-1 gap-1 p-2 bg-base-100" tabindex="0">
      <progress *ngIf="isNotificationsLoading | async" class="progress progress-primary"></progress>
      <at-notification-list-item
        *ngFor="let notification of itemsData"
        [notification]="notification"
        [selectedNotification]="selectedNotification$ | async"
        (notificationSelect)="onSelectNotification($event)"
      ></at-notification-list-item>
      <div class="flex justify-center items-center my-1">
        <button class="btn btn-ghost btn-block" (click)="getMoreNotification()">show more</button>
      </div>
    </div>
  </ng-container>
</ng-container>
