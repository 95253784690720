import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoadingModesEnum } from '../../../enums/shared/loading-modes.enum';

@Component({
  selector: 'at-custom-loading-indicator',
  templateUrl: './custom-loading-indicator.component.html',
  styleUrls: ['./custom-loading-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomLoadingIndicatorComponent {
  @Input() mode: LoadingModesEnum = LoadingModesEnum.navigation;

  LOADING_MODES = LoadingModesEnum;
}
