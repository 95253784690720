import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.state';
import { UserRolesEnum } from '../../../enums/auth/user-roles.enum';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const currentUser = createSelector(selectAuthState, (auth) => auth.user);
export const isLoggedIn = createSelector(selectAuthState, (auth) => !!auth.user);
export const isLoginLoading = createSelector(selectAuthState, (auth) => auth.loading);

export const userInfo = createSelector(selectAuthState, (auth) => auth.userInfo);
export const userRole = createSelector(userInfo, (userInfo) => (userInfo ? userInfo.role : undefined));
export const isUser = createSelector(userRole, (userRole) => !!userRole && userRole === UserRolesEnum.USER);
export const isAdmin = createSelector(userRole, (userRole) => !!userRole && userRole === UserRolesEnum.ADMIN);

export const loginFailed = createSelector(selectAuthState, (auth) => !!auth.error);
export const loginError = createSelector(selectAuthState, (auth) => auth.error);

export const isLoggedOut = createSelector(isLoggedIn, (loggedIn) => !loggedIn);
