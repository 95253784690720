import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_KEYS } from '../../../constants/shared/local-storage.constants';
import { StoreService } from '../../store/store.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  htmlTag: HTMLHtmlElement;
  constructor(private storeService: StoreService) {
    this.htmlTag = document.getElementsByTagName('html')[0];
    this.preSelectTheme();
  }

  selectTheme(theme: string): void {
    this.htmlTag.setAttribute('data-theme', theme);
    localStorage.setItem(LOCAL_STORAGE_KEYS.THEME, theme);
  }

  getSelectedTheme(): string | null {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.THEME);
  }

  preSelectTheme(): void {
    const selectedTheme = this.getSelectedTheme();
    if (!!selectedTheme) {
      this.htmlTag.setAttribute('data-theme', selectedTheme);
      this.storeService.setTheme(selectedTheme);
    }
  }
}
