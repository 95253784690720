import {
  faBell,
  faFileAudio,
  faFileImport,
  faFileExport,
  faFloppyDisk,
  faCheck,
  faCheckDouble,
} from '@fortawesome/pro-light-svg-icons';

export const LIGHT_ICONS = [faBell, faFileAudio, faFloppyDisk, faFileImport, faFileExport, faCheck, faCheckDouble];
