import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getSelectors, RouterReducerState } from '@ngrx/router-store';
import { AnnotationModulesEnum } from '../enums/navigation/route.enum';
import { GlobalToastState, ThemeState } from './app.state';

export const selectRouterState = createFeatureSelector<RouterReducerState>('router');
export const selectGlobalToastState = createFeatureSelector<GlobalToastState>('globalToast');
export const selectThemeState = createFeatureSelector<ThemeState>('theme');

const { selectUrl } = getSelectors(selectRouterState);

export const selectAnnotationModule = createSelector(selectUrl, (url) => {
  if (!url) {
    return AnnotationModulesEnum.DEFAULT;
  }
  if (url.includes(AnnotationModulesEnum.AUDIO)) {
    return AnnotationModulesEnum.AUDIO;
  }
  if (url.includes(AnnotationModulesEnum.IMAGE)) {
    return AnnotationModulesEnum.IMAGE;
  }
  return AnnotationModulesEnum.DEFAULT;
});

export const selectGlobalToast = createSelector(selectGlobalToastState, ({ toast }) => toast);
export const selectTheme = createSelector(selectThemeState, ({ selectedTheme }) => selectedTheme);
