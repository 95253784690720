import {
  faPalette,
  faWaveform,
  faFolderOpen,
  faHeadphonesSimple,
  faFileExport,
  faFileImport,
  faDownload,
  faFolderArrowDown,
  faFloppyDisk,
  faFloppyDisks,
  faTrashXmark,
  faFloppyDiskCircleArrowRight,
} from '@fortawesome/pro-regular-svg-icons';

export const REGULAR_ICONS = [
  faPalette,
  faWaveform,
  faFolderOpen,
  faHeadphonesSimple,
  faFileImport,
  faFileExport,
  faDownload,
  faFolderArrowDown,
  faFloppyDisk,
  faFloppyDisks,
  faTrashXmark,
  faFloppyDiskCircleArrowRight,
];
