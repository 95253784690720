<div *ngIf="message" class="toast-container" [ngSwitch]="type">
  <div
    class="alert bg-opacity-60 backdrop-blur-lg"
    [ngClass]="{
      'alert-info': type === ToastTypesEnum.info,
      'alert-success': type === ToastTypesEnum.success,
      'alert-warning': type === ToastTypesEnum.warning,
      'alert-error': type === ToastTypesEnum.error
    }"
  >
    <div>
      <button class="btn-ghost btn-circle btn-sm" (click)="closeToast()">
        <fa-icon [icon]="['fas', 'close']"></fa-icon>
      </button>
      <div [innerHTML]="message"></div>
    </div>
  </div>
</div>
