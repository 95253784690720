<div
  *ngIf="notification"
  class="notification-container"
  [ngClass]="{ selected: selectedNotification && selectedNotification.id === notification.id }"
  (click)="onSelectNotification($event)"
>
  <!--<div class="notification-header">
    {{ notification.sender.first_name }} {{ notification.sender.last_name }} has requested your review
  </div>-->
  <div class="sender-avatar-container">
    <at-custom-avatar [text]="senderNameInitials"></at-custom-avatar>
  </div>
  <div class="notification-info-container">
    <div class="sender-info-container">
      <span class="font-bold">{{ notification.sender.first_name }} {{ notification.sender.last_name }}</span>
      has requested your review
    </div>
    <div class="notification-note">
      <fa-icon [icon]="['fas', 'circle-question']"></fa-icon>
      <div class="notification-note-subtitle">"{{ notification.reviewNote }}"</div>
    </div>
    <div class="notification-footer">
      <div class="notification-date">{{ notification.reportTime | date: "medium" }}</div>
      <div class="notification-status">
        <fa-icon *ngIf="notification.status === NOTIFICATION_STATUS_ENUM.sent" [icon]="['fal', 'check']"></fa-icon>
        <fa-icon
          *ngIf="notification.status === NOTIFICATION_STATUS_ENUM.delivered"
          [icon]="['fal', 'check-double']"
        ></fa-icon>
        <fa-icon
          class="text-primary"
          *ngIf="notification.status === NOTIFICATION_STATUS_ENUM.seen"
          [icon]="['fal', 'check-double']"
        ></fa-icon>
      </div>
    </div>
  </div>
</div>
