import { HttpClient, HttpEvent, HttpEventType, HttpParams } from '@angular/common/http';
import { ColorCodeEnum } from '@enums';
import {
  DynamicTableData,
  DynamicTableHeaderInterface,
  DynamicTableInterface,
  DynamicTableRequestInterface,
  DynamicTableTypesEnum,
} from '@interfaces';
import { Observable } from 'rxjs';

export function isNonNull<T>(value: T): value is NonNullable<T> {
  return !!value;
}

export function getCssVariable(cssVariable: ColorCodeEnum): string {
  return window.getComputedStyle(document.body).getPropertyValue(`--${cssVariable}`);
}

export function getCssColorVariableInHex(cssVariable: ColorCodeEnum): string {
  const [h, s, l] = splitHslCssVariable(getCssVariable(cssVariable));
  return hslToHex(h, s, l);
}

export function splitHslCssVariable(hsl: string): number[] {
  return hsl
    .trim()
    .split(' ')
    .map((i) => parseFloat(i));
}

export function hslToHex(h: number, s: number, l: number) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export const getColorByCode = (colorCode: ColorCodeEnum, percentage = 100): string =>
  colorCode ? `hsl(var(--${colorCode}) / ${percentage}%)` : null;

export const base64ToFile = (dataUrl: string, fileName: string) =>
  fetch(dataUrl)
    .then((data) => data.blob())
    .then((blob) => new File([blob], fileName));

export function onHttpEvent<T>(
  event: HttpEvent<any>,
  onSent: () => T,
  onUploadProgress?: (progress: number) => T,
  onDownloadProgress?: (progress: number) => T,
  onComplete?: (response: any) => T,
  onError?: (error: any) => any
): any {
  if (event.type === HttpEventType.Sent && onSent) {
    return onSent();
  }
  if (event.type === HttpEventType.UploadProgress && onUploadProgress) {
    const progress = Math.round((100 * event.loaded) / event.total);
    return onUploadProgress(progress);
  }
  if (event.type === HttpEventType.DownloadProgress && onDownloadProgress) {
    const progress = Math.round((100 * event.loaded) / event.total);
    return onDownloadProgress(progress);
  }
  if ((event.type === HttpEventType.Response || event.type === HttpEventType.ResponseHeader) && onComplete) {
    if (event.status === 200) {
      return onComplete(event);
    } else {
      return onError(event.statusText);
    }
  }
}

/**
 * Adapts list of items of any types to DynamicTableInterface
 * The purpose of this function is to convert any type of list in the Frontend domain to be use by the DynamicTableComponent
 * @param {any[]} genericList - Generic List of objects to be converted to the DynamicTableInterface type
 * @returns {DynamicTableInterface} - The adapted list
 */
export function getDynamicTableList(genericList: any[]): DynamicTableInterface {
  let headers: DynamicTableHeaderInterface[] = [];
  let data: DynamicTableData = [];
  const totalRows = genericList.length;
  if (!genericList.length) {
    return { headers, data, totalRows };
  }
  headers = Object.keys(genericList[0]).map((key) => ({
    key,
    name: key,
    type: key.toLowerCase().includes('id') ? DynamicTableTypesEnum.id : DynamicTableTypesEnum.string,
    sortable: false,
  }));
  data = genericList;
  return { headers, data, totalRows };
}

export function dynamicTableRequest(
  httpClient: HttpClient,
  endpoint: string,
  request: DynamicTableRequestInterface
): Observable<DynamicTableInterface> {
  let params = new HttpParams();
  if (request.limit !== undefined) {
    params = params.append('limit', request.limit);
  }
  if (request.offset !== undefined) {
    params = params.append('offset', request.offset);
  }
  if (request.column !== undefined) {
    params = params.append('column', request.column);
  }
  if (request.direction !== undefined) {
    params = params.append('direction', request.direction);
  }
  return httpClient.get<DynamicTableInterface>(endpoint, { params });
}
