import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AnnotationModulesEnum, MenuItemTypesEnum } from '@enums';
import { THEMES } from '../../../constants/shared/themes.constants';
import { StoreService } from '../../../services/store/store.service';
import { map, Observable } from 'rxjs';
import { NAVIGATION_LIST } from '../../../constants/navigation/navigation.constants';
import { NotificationResponseInterface } from '@interfaces';

@Component({
  selector: 'at-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  @Input() currentModule: AnnotationModulesEnum | null;
  isLoggedIn$ = this.storeService.getIsLoggedIn();
  annotationModule$ = this.storeService.getCurrentAnnotationModule();
  isAnnotationModuleSelected$ = this.annotationModule$.pipe(map((annotationModule) => !!annotationModule));

  isUserInfoPresent$ = this.storeService.getCurrentUserInfo().pipe(map((userInfo) => !!userInfo));

  isAdmin$ = this.storeService.getIsAdmin();
  isUser$ = this.storeService.getIsUser();

  userInitials$: Observable<string | undefined> = this.storeService
    .getCurrentUserInfo()
    .pipe(
      map((userInfo) => (!!userInfo ? `${userInfo.first_name[0]}${userInfo.last_name[0]}`.toUpperCase() : undefined))
    );

  userFullName$: Observable<string | undefined> = this.storeService
    .getCurrentUserInfo()
    .pipe(map((userInfo) => (!!userInfo ? `${userInfo.first_name} ${userInfo.last_name}` : undefined)));

  notifications$: Observable<NotificationResponseInterface[]> = this.storeService.getNotifications();
  unseenNotificationsCount$: Observable<number> = this.notifications$.pipe(
    map((notifications) => notifications.filter((notification) => notification.status !== 'seen')),
    map((unseenNotifications) => unseenNotifications.length)
  );
  MENU_ITEM_TYPE = MenuItemTypesEnum;
  THEMES = THEMES;
  NAVIGATION_LIST = NAVIGATION_LIST;

  constructor(private storeService: StoreService) {}

  logout() {
    this.storeService.logout();
  }
}
