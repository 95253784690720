import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, map, Observable, throwError } from 'rxjs';
import { StoreService } from '../services/store/store.service';
import { AuthService } from '../services/api/auth/auth.service';
import { UserInterface } from '../interfaces/auth/user.interface';
import { memoizedUserinfo } from './resolvers.utils';
import { UserRolesEnum } from '../enums/auth/user-roles.enum';
import { AnnotationModulesEnum, RouteEnum } from '../enums/navigation/route.enum';

@Injectable({
  providedIn: 'root',
})
export class AdminResolver implements Resolve<boolean> {
  constructor(private storeService: StoreService, private authService: AuthService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const currentModule = state.url.includes(AnnotationModulesEnum.AUDIO)
      ? AnnotationModulesEnum.AUDIO
      : AnnotationModulesEnum.IMAGE;
    return memoizedUserinfo(this.authService, this.storeService).pipe(
      map((userInfo: UserInterface) => {
        if (userInfo.role !== UserRolesEnum.ADMIN) {
          this.router.navigate([currentModule, RouteEnum.dashboard]);
          return false;
        }
        return true;
      }),
      catchError((error) => {
        this.router.navigate([RouteEnum.signIn]);
        return throwError(error);
      })
    );
  }
}
