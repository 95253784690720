import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CustomSelectionMenuComponent } from './custom-selection-menu/custom-selection-menu.component';
import { ThemeSelectionItemComponent } from './custom-selection-menu/theme-selection-item/theme-selection-item.component';
import { ThemeService } from '../../services/worker/theme/theme.service';
import { ElyadataLogoComponent } from './elyadata-logo/elyadata-logo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomLoadingIndicatorComponent } from './custom-loading-indicator/custom-loading-indicator.component';
import { RouterModule } from '@angular/router';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { CustomToastComponent } from './custom-toast/custom-toast.component';
import { FilesListComponent } from './files-list/files-list.component';
import { UsersListComponent } from './users-list/users-list.component';
import { CustomAvatarComponent } from './custom-avatar/custom-avatar.component';
import { UserInitialsPipe } from '../../pipes/user-initials.pipe';
import { UserInfoSummaryPipe } from '../../pipes/user-info-summary.pipe';
import { FileInfoSummaryPipe } from '../../pipes/file-info-summary.pipe';
import { AnnotationStatisticsComponent } from './annotation-statistics/annotation-statistics.component';
import { CustomColorPickerComponent } from './custom-color-picker/custom-color-picker.component';
import { CssColorVariablePipe } from '../../pipes/css-color-variable.pipe';
import { TimestampPipe } from '../../pipes/timestamp.pipe';
import { TranscriptionActionsComponent } from './transcription-actions/transcription-actions.component';
import { CustomSliderComponent } from './custom-slider/custom-slider.component';
import { ExportFileComponent } from './export-file/export-file.component';
import { ImportFileComponent } from './import-file/import-file.component';
import { CustomInputAutocompleteComponent } from './custom-input-autocomplete/custom-input-autocomplete.component';
import { PlayTrackControlsComponent } from './play-track-controls/play-track-controls.component';
import { AlertComponent } from './alert/alert.component';
import { RadialProgressComponent } from './radial-progress/radial-progress.component';
import { EntityCardComponent } from './entity-card/entity-card.component';
import { CustomLoadingPlaceholderComponent } from './custom-loading-placeholder/custom-loading-placeholder.component';
import { CustomModalComponent } from './custom-modal/custom-modal.component';
import { CustomFileUploadProgressComponent } from './custom-file-upload-progress/custom-file-upload-progress.component';
import { FilesizePipe } from '../../pipes/filesize.pipe';
import { TogglePasswordDirective } from './directives/toggle-password.directive';
import { DynamicTableComponent } from './dynamic-table/dynamic-table.component';
import { CustomPaginatorComponent } from './custom-paginator/custom-paginator.component';
import { TranscriptTextItemComponent } from './transcript-text-item/transcript-text-item.component';
import { CustomConfirmModalComponent } from './custom-confirm-modal/custom-confirm-modal.component';
import { UserSelectItemComponent } from './user-select-item/user-select-item.component';
import { NotificationListItemComponent } from './notification-list-item/notification-list-item.component';
import { CustomChipSelectComponent } from './custom-chip-select/custom-chip-select.component';
import { SOLID_ICONS } from 'src/app/constants/shared/icons/fas-icons.constant';
import { REGULAR_ICONS } from '../../constants/shared/icons/far-icons.constant';
import { DUO_TONE_ICONS } from '../../constants/shared/icons/fad-icons.constant';
import { LIGHT_ICONS } from '../../constants/shared/icons/fal-icons.constant';
import { DurationPipe } from '../../pipes/duration.pipe';
import { WelcomeHeaderComponent } from './welcome-header/welcome-header.component';

@NgModule({
  declarations: [
    CustomSelectionMenuComponent,
    ThemeSelectionItemComponent,
    ElyadataLogoComponent,
    CustomLoadingIndicatorComponent,
    UnderConstructionComponent,
    CustomToastComponent,
    FilesListComponent,
    UsersListComponent,
    CustomAvatarComponent,
    UserInitialsPipe,
    UserInfoSummaryPipe,
    FileInfoSummaryPipe,
    AnnotationStatisticsComponent,
    CustomColorPickerComponent,
    CssColorVariablePipe,
    TimestampPipe,
    TranscriptionActionsComponent,
    CustomSliderComponent,
    ImportFileComponent,
    ExportFileComponent,
    CustomInputAutocompleteComponent,
    PlayTrackControlsComponent,
    AlertComponent,
    RadialProgressComponent,
    EntityCardComponent,
    CustomLoadingPlaceholderComponent,
    CustomModalComponent,
    CustomFileUploadProgressComponent,
    FilesizePipe,
    TogglePasswordDirective,
    DynamicTableComponent,
    CustomPaginatorComponent,
    TranscriptTextItemComponent,
    CustomConfirmModalComponent,
    UserSelectItemComponent,
    NotificationListItemComponent,
    CustomChipSelectComponent,
    DurationPipe,
    WelcomeHeaderComponent,
  ],
  imports: [CommonModule, FontAwesomeModule, FormsModule, ReactiveFormsModule, RouterModule],
  exports: [
    FontAwesomeModule,
    ReactiveFormsModule,
    RouterModule,
    CustomSelectionMenuComponent,
    ElyadataLogoComponent,
    CustomLoadingIndicatorComponent,
    UnderConstructionComponent,
    CustomToastComponent,
    FilesListComponent,
    UsersListComponent,
    UserInitialsPipe,
    CustomAvatarComponent,
    UserInfoSummaryPipe,
    FileInfoSummaryPipe,
    AnnotationStatisticsComponent,
    CustomColorPickerComponent,
    TimestampPipe,
    TranscriptionActionsComponent,
    CustomSliderComponent,
    ImportFileComponent,
    RadialProgressComponent,
    ExportFileComponent,
    CustomInputAutocompleteComponent,
    PlayTrackControlsComponent,
    AlertComponent,
    EntityCardComponent,
    CustomModalComponent,
    CustomFileUploadProgressComponent,
    FilesizePipe,
    TogglePasswordDirective,
    CustomLoadingPlaceholderComponent,
    DynamicTableComponent,
    TranscriptTextItemComponent,
    CustomPaginatorComponent,
    CustomConfirmModalComponent,
    CustomChipSelectComponent,
    DurationPipe,
    WelcomeHeaderComponent,
  ],
  providers: [ThemeService],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(...SOLID_ICONS, ...REGULAR_ICONS, ...DUO_TONE_ICONS, ...LIGHT_ICONS);
  }
}
