import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import { authReducer } from '../components/auth/store/auth.reducer';
import { AppState, GlobalToastState, ThemeState } from './app.state';

import { routerReducer } from '@ngrx/router-store';
import { annotationReducer } from '../components/annotation/store/annotation.reducer';
import { setGlobalToast, setTheme } from './app.actions';

export const initialGlobalToastState: GlobalToastState = {
  toast: null,
};

export const initialThemeState: ThemeState = {
  selectedTheme: null,
};

export const globalToastReducer = createReducer(
  initialGlobalToastState,
  on(setGlobalToast, (state, { toast }) => ({
    ...state,
    toast,
  }))
);

export const themeReducer = createReducer(
  initialThemeState,
  on(setTheme, (state, { selectedTheme }) => ({
    ...state,
    selectedTheme,
  }))
);

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  auth: authReducer,
  annotation: annotationReducer,
  globalToast: globalToastReducer,
  theme: themeReducer,
};
