import { RouteEnum } from '../../enums/navigation/route.enum';

export const NAVIGATION_LIST = {
  admin: [
    {
      route: RouteEnum.home,
      title: 'Home',
      single: true,
    },
    {
      route: RouteEnum.dashboard,
      title: 'Dashboard',
      single: false,
    },
    {
      route: RouteEnum.files,
      title: 'Files',
      single: false,
    },
    {
      route: RouteEnum.users,
      title: 'Users',
      single: false,
    },
    {
      route: RouteEnum.projects,
      title: 'Projects',
      single: false,
    },
  ],

  user: [
    {
      route: RouteEnum.home,
      title: 'Home',
      single: true,
    },
    {
      route: RouteEnum.dashboard,
      title: 'Dashboard',
      single: false,
    },
    {
      route: RouteEnum.annotation,
      title: 'Annotation',
      single: false,
    },
  ],
};
