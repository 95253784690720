import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { filter, map } from 'rxjs';
import { AnnotationModulesEnum, RouteEnum, UserRolesEnum } from '@enums';
import { StoreService } from '../services/store/store.service';
import { ToolboxActions } from '../components/toolbox/store/action-types';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AnnotationActions } from '../components/annotation/store/action-types';
import { NotificationStatusEnum } from '@interfaces';

@Injectable()
export class RouterEffects {
  setAnnotationFileIdQueryParamEffect$;
  setAnnotationUserIdQueryParamEffect$;
  selectReviewNotificationEffect$;
  constructor(
    private actions$: Actions,
    private storeService: StoreService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.setAnnotationFileIdQueryParamEffect$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(routerNavigatedAction),
        map(({ payload }) => ({ url: payload.routerState.url, queryParams: payload.routerState.root.queryParams })),
        filter(
          ({ url, queryParams }) =>
            url.includes(AnnotationModulesEnum.AUDIO + '/' + RouteEnum.annotation) && !!queryParams['fileId']
        ),
        concatLatestFrom(({ queryParams }) =>
          this.storeService
            .getToolboxFilesList()
            .pipe(map((filesList) => filesList.find((file) => file.id === queryParams['fileId'])))
        ),
        map(([, file]) => ToolboxActions.setSelectedFile({ file }))
      );
    });

    this.setAnnotationUserIdQueryParamEffect$ = createEffect(
      () => {
        return this.actions$.pipe(
          ofType(routerNavigatedAction),
          map(({ payload }) => ({ queryParams: payload.routerState.root.queryParams })),
          filter(({ queryParams }) => !!queryParams['userId']),
          map(({ queryParams }) => queryParams['userId'] as string),
          concatLatestFrom((userId: string) => [
            this.storeService.getCurrentUserInfo().pipe(map((user) => !!user && user.role === UserRolesEnum.ADMIN)),
            this.storeService
              .getToolboxUsersList()
              .pipe(map((usersList) => usersList.find((user) => user.id === userId))),
          ]),
          tap(([, shouldKeep]) => {
            if (!shouldKeep) {
              this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: { userId: null },
                queryParamsHandling: 'merge',
              });
            }
          }),
          map(([, shouldKeep, user]) => ToolboxActions.setSelectedUser({ user: shouldKeep ? user : null }))
        );
      },
      { dispatch: true }
    );

    this.selectReviewNotificationEffect$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(AnnotationActions.setSelectedNotification),
        tap(({ notification: { transcriptId } }) => {
          this.router.navigate(['audio', 'annotation'], {
            queryParams: { ...transcriptId },
            queryParamsHandling: 'merge',
          });
        }),
        filter(({ notification: { status } }) => status !== NotificationStatusEnum.seen),
        map(({ notification: { id } }) =>
          AnnotationActions.initiateNotificationUpdate({ id, status: NotificationStatusEnum.seen })
        )
      );
    });
  }
}
