import { createAction, props } from '@ngrx/store';
import {
  DynamicDetailsInterface,
  NotificationResponseInterface,
  NotificationStatusEnum,
  SegmentInterface,
  ToolItem,
  TranscriptResponseInterface,
  UserInterface,
  WavePlayerParamsEncodedInterface,
} from '@interfaces';
import { SegmentDirectionEnum, TranscriptionDirectionEnum } from '@enums';
import { BufferRegionInterface } from './annotation.state';
import {
  ISaveSegmentActionConfig,
  ISetSelectedRegionActionConfig,
} from '../../../interfaces/actions-config/annotation';

// ================ Toolbar ===================//

export const setSelectedTool = createAction('[Annotation Toolbar] Select Tool', props<{ tool: ToolItem }>());

// ================= Transcription ================//

export const loadTranscriptStats = createAction(
  '[Annotation ToolBox] Load Transcript Stats',
  props<{
    userId: string;
    fileId: string;
  }>()
);

export const setTranscriptStats = createAction(
  '[Annotation ToolBox] Set Transcript Stats',
  props<{ details: DynamicDetailsInterface }>()
);

export const setTranscriptStatsLoading = createAction(
  '[Annotation ToolBox] Set Transcript Stats Loading',
  props<{ isLoading: boolean }>()
);

export const loadTranscriptData = createAction(
  '[Annotation Toolbox] Load Transcript Data',
  props<{
    userId: string;
    fileId: string;
  }>()
);

export const setTranscriptData = createAction(
  '[Annotation Toolbox] Set Transcript Data',
  props<{ transcriptData: TranscriptResponseInterface }>()
);

export const setTranscriptLoading = createAction(
  '[Annotation Toolbox] Set Transcript Loading',
  props<{ loading: boolean }>()
);

export const setTranscriptDirection = createAction(
  '[Annotation Transcript] Set Transcript Direction',
  props<{ direction: TranscriptionDirectionEnum }>()
);

export const loadSpeakerSuggestions = createAction(
  '[Annotation Transcript Details] Load Speakers Suggestion',
  props<{ query: string }>()
);

export const setSpeakersSuggestion = createAction(
  '[Annotation Transcript Details] Set Speakers Suggestions',
  props<{ suggestions: string[] }>()
);

export const setSelectedSpeaker = createAction(
  '[Annotation Transcript Details] Set Selected Speaker',
  props<{ speaker: string }>()
);

export const setSpeakerSuggestionLoading = createAction(
  '[Annotation Transcript Details] Set Speakers Suggestions loading',
  props<{ isLoading: boolean }>()
);

// ============= Segments ============//

export const saveSegment = createAction(
  '[Annotation segment] Save Segment',
  props<{
    /** segment to be saved **/
    segment: SegmentInterface;
    /** segment save action config **/
    config?: ISaveSegmentActionConfig;
  }>()
);
export const setSegmentSaveLoading = createAction(
  '[Annotation Wave] Set Segment Save Loading',
  props<{ loading: boolean }>()
);

export const setSegmentDeletionModalState = createAction(
  '[Annotation Wave] Set Segment Deletion Modal State',
  props<{ isOpen: boolean }>()
);
export const saveAllSegments = createAction(
  '[Annotation segment] Save All Segments',
  props<{ segmentsData: TranscriptResponseInterface }>()
);
export const deleteSegment = createAction('[Annotation segment] Delete Segment', props<{ segmentId: string }>());

export const setSegmentDeleteLoading = createAction(
  '[Annotation Wave] Set Segment Delete Loading',
  props<{ loading: boolean }>()
);
export const navigateSegmentNeighbor = createAction(
  '[Annotation Wave] Navigate To Segment Neighbor',
  props<{ navigation: SegmentDirectionEnum }>()
);

export const setSegmentNavigationLoading = createAction(
  '[Annotation Wave] Set Segment Navigation Loading',
  props<{ loading: boolean }>()
);

// ================= Wave =============== //

export const loadWave = createAction('[Annotation Wave] Load Wave By File Id', props<{ fileId: string }>());
export const setWaveParams = createAction(
  '[Annotation Wave] Set Wave Peaks',
  props<{ waveParams: WavePlayerParamsEncodedInterface }>()
);

export const setSelectedRegion = createAction(
  '[Annotation Wave] Set Selected Region',
  props<{ selectedRegion: SegmentInterface; config?: ISetSelectedRegionActionConfig }>()
);

export const setSelectedRegionParams = createAction(
  '[Annotation Wave] Set Selected Region Params',
  props<{ selectedRegionParams: Partial<SegmentInterface> }>()
);

export const setCurrentWaveProgress = createAction(
  '[Annotation Wave] Set Current Wave Progress',
  props<{ progress: number }>()
);

export const setWaveLoading = createAction('[Annotation Wave] Set Wave Loading', props<{ isLoading: boolean }>());
export const setWaveLoaded = createAction('[Annotation Wave] Set Wave Loaded', props<{ isLoaded: boolean }>());

export const setBufferRegionParams = createAction(
  '[Annotation Wave] Set Buffer Region Params',
  props<{ params: Partial<BufferRegionInterface> }>()
);

export const loadAdminList = createAction('[Annotation Request Review] Load Admins List');
export const setAdminList = createAction(
  '[Annotation Request Review] Set Admins List',
  props<{ adminList: UserInterface[] }>()
);
export const setAdminListLoading = createAction(
  '[Annotation Request Review] Set Admin List loading',
  props<{ loading: boolean }>()
);

export const setSelectedReviewerId = createAction(
  '[Annotation Request Review] Set Selected Reviewer',
  props<{ reviewerId: string }>()
);

export const setReviewNote = createAction(
  '[Annotation Request Review] Set Review Note',
  props<{ reviewNote: string }>()
);

export const pushNotification = createAction('[Annotation Request Review] Push Review Request Notification');
export const setNotificationsList = createAction(
  '[Annotation Notifications] Set Notification List',
  props<{ notifications: NotificationResponseInterface[] }>()
);
export const setNotificationsLoading = createAction(
  '[Annotation Notifications] Set Notification Loading',
  props<{ loading: boolean }>()
);
export const setSelectedNotification = createAction(
  '[Annotation Notifications] Set Selected Notification',
  props<{ notification: NotificationResponseInterface }>()
);

export const initiateNotificationUpdate = createAction(
  '[Annotation Notifications] Init Update Notification Status',
  props<{ id: string; status: NotificationStatusEnum }>()
);

export const updateNotification = createAction(
  '[Annotation Notifications] Update Notification Status',
  props<{ id: string; status: NotificationStatusEnum }>()
);

export const loadMoreNotification = createAction('[Annotation Notifications] Load More Notifications');
