export enum FileStatusEnum {
  READY = 'Ready',
  IN_PROGRESS = 'In Progress',
  DONE = 'Done',
}

export enum SpeakerGenderEnum {
  male = 'Male',
  female = 'Female',
  default = 'Undefined',
}

export enum SegmentDirectionEnum {
  previous = 'previous',
  next = 'next',
}

export enum SegmentActionEnum {
  save,
  delete,
  navigate,
  help,
}
