import { createAction, props } from '@ngrx/store';
import { FileStatusEnum, ActionTypesEnum, UploadStatusEnum } from '@enums';
import { UploadTranscriptFileInterface, FileInterface, UserInterface } from '@interfaces';
import { ISetSelectedUserActionConfig } from '../../../interfaces/actions-config/annotation';

// ============ Files tool =================//
export const setFilesList = createAction('[Annotation Toolbox] Set Files List', props<{ files: FileInterface[] }>());
export const setIsFilesLoading = createAction(
  '[Annotation Toolbox] Set Files Loading',
  props<{ isLoading: boolean }>()
);
export const setIsFilesLoaded = createAction(
  '[Annotation Toolbox] Set Files Loaded',
  props<{ isFilesLoaded: boolean }>()
);
export const setSelectedFile = createAction('[Annotation Toolbox] Select File', props<{ file: FileInterface }>());
export const setUsersList = createAction('[Annotation Toolbox] Set Users List', props<{ users: UserInterface[] }>());
export const setIsUsersLoading = createAction(
  '[Annotation Toolbox] Set Users Loading',
  props<{ isLoading: boolean }>()
);
export const setIsUsersLoaded = createAction(
  '[Annotation Toolbox] Set Users Loaded',
  props<{ isUsersLoaded: boolean }>()
);
export const setSelectedUser = createAction(
  '[Annotation Toolbox] Select User',
  props<{ user: UserInterface; config?: ISetSelectedUserActionConfig }>()
);

// ================== Segments Tools ===================== //

export const deleteSegment = createAction(
  '[Annotation Segmentation Toolbox] Delete Segment',
  props<{ segmentId: string }>()
);

// ================== Save Handler Tools ===================== //

export const uploadTranscriptFileRequest = createAction(
  ActionTypesEnum.UPLOAD_REQUEST,
  props<{ transcriptFileData: UploadTranscriptFileInterface }>()
);
export const uploadTranscriptFileStatus = createAction(
  ActionTypesEnum.UPLOAD_STATUS,
  props<{ status: UploadStatusEnum; progress?: number; error?: string }>()
);
export const uploadTranscriptFileCancel = createAction(ActionTypesEnum.UPLOAD_CANCEL);
export const downloadTranscriptFile = createAction(ActionTypesEnum.DOWNLOAD_REQUEST, props<{ fileFormat: string }>());
export const downloadTranscriptFileCompleted = createAction(ActionTypesEnum.DOWNLOAD_COMPLETED);

// ================= Zoom Tools ==================== //

export const setHorizontalZoom = createAction(
  '[Annotation Zoom Toolbox] Set Horizontal Zoom',
  props<{ value: number }>()
);

export const setVerticalZoom = createAction('[Annotation Zoom Toolbox] Set Vertical Zoom', props<{ value: number }>());

// ================ Common Tools ================ //

export const setFileStatus = createAction(
  '[Annotation Common Toolbox] Set File Transcript Status',
  props<{ fileStatus: FileStatusEnum }>()
);
