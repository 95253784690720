import { createReducer, on } from '@ngrx/store';
import { ProjectsDashboardActions } from './actions-types';

import { ProjectsDashboardState } from './projects.state';

export const projectsFeatureKey = 'projects';

export const initialState: ProjectsDashboardState = {
  projectsList: null,
  isLoading: false,
};

export const ProjectsDashboardReducer = createReducer(
  initialState,
  on(ProjectsDashboardActions.setProjectsList, (state, { projectsList }): ProjectsDashboardState => {
    return { ...state, projectsList };
  }),
  on(
    ProjectsDashboardActions.setProjectsLisLoading,
    (state, { isLoading }): ProjectsDashboardState => ({
      ...state,
      isLoading,
    })
  )
);
