export enum DashboardActionsEnum {
  LOAD_FILES = '[Admin Dashboard] Load Files',
  LOAD_USERS = '[Admin Dashboard] Load Users',
  LOAD_PROJECTS = '[Admin Dashboard] Load Projects',

  SET_FILES_LOADING = '[Admin Dashboard] Set Files Loading',
  SET_PROJECTS_LOADING = '[Admin Dashboard] Set Projects Loading',
  SET_USERS_LOADING = '[Admin Dashboard] Set Users Loading',

  SET_FILES_COUNT = '[Admin Dashboard] Set Files Count',
  SET_PROJECTS_COUNT = '[Admin Dashboard] Set Projects Count',
  SET_USERS_COUNT = '[Admin Dashboard] Set Users Count',

  SET_FILES_UPLOAD_LIST = '[Admin Dashboard] Set Files Upload List',
  SET_FILES_UPLOAD_PROGRESS = '[Admin Dashboard] Set Files Upload Progress',
  SET_FILES_UPLOAD_COMPLETE = '[Admin Dashboard] Set Files Upload Complete',

  LOAD_ANNOTATOR_FILES_STATS = '[Annotator Dashboard] Load Annotator Assigned Files Stats',
  SET_ANNOTATOR_FILES_STATS_LOADING = '[Annotator Dashboard] Set Annotator Assigned Files Stats Loading',
  SET_ANNOTATOR_FILES_STATS = '[Annotator Dashboard] Set Annotator Assigned Files Stats',

  LOAD_ASSIGNED_FILES_LIST_BY_USER_ID = '[Dashboard] Load User Assigned Files List By User Id',
  SET_ASSIGNED_FILES_LIST_BY_USER_ID = '[Dashboard] Set User Assigned Files List By User Id',
  SET_ASSIGNED_FILES_LIST_BY_USER_ID_LOADING = '[Dashboard] Set User Assigned Files List By User Id Loading',
  SET_ASSIGNED_FILES_LIST_BY_USER_ID_FAILED = '[Dashboard] Set User Assigned Files List By User Id Failed',
}

export enum FilesDashboardEnum {
  LOAD_FILES = '[Admin Files Dashboard] Load Files',
  SET_FILES_LIST = '[Admin Files Dashboard] Set Files List',
  SET_FILES_LOADING = '[Admin Files Dashboard] Set Files List Loading',
  LOAD_PROJECTS = '[Admin Files Dashboard] Load Projects',
  SET_PROJECTS = '[Admin Files Dashboard] Set Projects List',
  SET_PROJECTS_LOADING = '[Admin Files Dashboard] Set Projects List Loading',
  LOAD_USERS = '[Admin Files Dashboard] Load Users',
  SET_USERS = '[Admin Files Dashboard] Set Users',
  SET_USERS_LOADING = '[Admin Files Dashboard] Set Users List Loading',
}

export enum ProjectsDashboardEnum {
  LOAD_PROJECTS = '[Admin Projects Dashboard] Load Projects',
  SET_PROJECTS_LIST = '[Admin Projects Dashboard] Set Projects List',
  SET_PROJECTS_LOADING = '[Admin Projects Dashboard] Set Projects List Loading',
}

export enum UsersDashboardActionEnum {
  LOAD_USERS = '[Admin Users Dashboard] Load Users',
  SET_USERS_LIST = '[Admin Users Dashboard] Set Users List',
  SET_USERS_LIST_LOADING = '[Admin Users Dashboard] Set Users List Loading',
}
