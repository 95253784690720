import { AnnotationState } from './annotation.state';
import { createReducer, on } from '@ngrx/store';
import { AnnotationActions } from './action-types';
import { ToolboxActions } from '../../toolbox/store/action-types';
import { TranscriptionDirectionEnum } from '@enums';

export const annotationFeatureKey = 'annotation';
export const initialAnnotationState: AnnotationState = {
  toolbar: {
    selectedTool: null,
  },
  transcript: {
    stats: { details: null, isLoading: false },
    transcriptData: null,
    isTranscriptLoading: false,
    transcriptDirection: TranscriptionDirectionEnum.rtl,
    speakersSuggestions: {
      selectedSpeaker: null,
      suggestions: null,
      isLoading: false,
    },
  },
  segments: {
    saveLoading: false,
    deleteLoading: false,
    segmentDeletionModalState: false,
    navigationLoading: false,
  },
  wave: {
    waveParams: null,
    selectedRegion: { segment: null, config: { manual: false } },
    bufferRegion: {
      start: null,
      end: null,
    },
    currentProgress: 0,
    isLoaded: false,
    isLoading: false,
  },
  requestReview: {
    adminList: [],
    loading: false,
    selectedReviewerId: null,
    reviewNote: null,
  },
  reviewNotifications: {
    notifications: [],
    loading: false,
    selectedNotification: null,
  },
};

export const annotationReducer = createReducer(
  initialAnnotationState,
  on(
    AnnotationActions.setSelectedTool,
    (state, { tool }): AnnotationState => ({
      ...state,
      toolbar: { ...state.toolbar, selectedTool: tool },
    })
  ),

  // ================= Transcript ==================== //

  on(
    AnnotationActions.setTranscriptStats,
    (state, { details }): AnnotationState => ({
      ...state,
      transcript: {
        ...state.transcript,
        stats: {
          ...state.transcript.stats,
          details,
        },
      },
    })
  ),
  on(
    AnnotationActions.setTranscriptStatsLoading,
    (state, { isLoading }): AnnotationState => ({
      ...state,
      transcript: {
        ...state.transcript,
        stats: {
          ...state.transcript.stats,
          isLoading,
        },
      },
    })
  ),
  on(
    AnnotationActions.setTranscriptData,
    (state, { transcriptData }): AnnotationState => ({
      ...state,
      transcript: {
        ...state.transcript,
        transcriptData,
      },
    })
  ),
  on(
    AnnotationActions.setTranscriptLoading,
    (state, { loading }): AnnotationState => ({
      ...state,
      transcript: {
        ...state.transcript,
        isTranscriptLoading: loading,
      },
    })
  ),
  on(
    AnnotationActions.setTranscriptDirection,
    (state, { direction: transcriptDirection }): AnnotationState => ({
      ...state,
      transcript: {
        ...state.transcript,
        transcriptDirection,
      },
    })
  ),
  on(
    AnnotationActions.setSpeakersSuggestion,
    (state, { suggestions }): AnnotationState => ({
      ...state,
      transcript: {
        ...state.transcript,
        speakersSuggestions: { ...state.transcript.speakersSuggestions, suggestions },
      },
    })
  ),
  on(
    AnnotationActions.setSelectedSpeaker,
    (state, { speaker }): AnnotationState => ({
      ...state,
      transcript: {
        ...state.transcript,
        speakersSuggestions: { ...state.transcript.speakersSuggestions, selectedSpeaker: speaker },
      },
    })
  ),

  on(
    AnnotationActions.setSpeakerSuggestionLoading,
    (state, { isLoading }): AnnotationState => ({
      ...state,
      transcript: {
        ...state.transcript,
        speakersSuggestions: { ...state.transcript.speakersSuggestions, isLoading },
      },
    })
  ),

  // ================ Wave ================= //

  on(
    AnnotationActions.setWaveParams,
    (state, { waveParams }): AnnotationState => ({ ...state, wave: { ...state.wave, waveParams } })
  ),
  on(
    AnnotationActions.setWaveLoading,
    (state, { isLoading }): AnnotationState => ({ ...state, wave: { ...state.wave, isLoading } })
  ),
  on(
    AnnotationActions.setWaveLoaded,
    (state, { isLoaded }): AnnotationState => ({ ...state, wave: { ...state.wave, isLoaded } })
  ),
  on(
    AnnotationActions.setSelectedRegion,
    (state, { selectedRegion, config }): AnnotationState => ({
      ...state,
      wave: { ...state.wave, selectedRegion: { ...state.wave.selectedRegion, segment: selectedRegion, config } },
    })
  ),
  on(
    AnnotationActions.setSelectedRegionParams,
    (state, { selectedRegionParams }): AnnotationState => ({
      ...state,
      wave: {
        ...state.wave,
        selectedRegion: {
          ...state.wave.selectedRegion,
          segment: { ...state.wave.selectedRegion.segment, ...selectedRegionParams },
        },
      },
    })
  ),
  on(
    ToolboxActions.deleteSegment,
    (state, { segmentId }): AnnotationState =>
      !!state.wave.selectedRegion && state.wave.selectedRegion.segment.segmentId === segmentId
        ? {
            ...state,
            wave: { ...state.wave, selectedRegion: null },
          }
        : state
  ),
  on(
    AnnotationActions.setCurrentWaveProgress,
    (state, { progress }): AnnotationState => ({
      ...state,
      wave: { ...state.wave, currentProgress: progress },
    })
  ),
  on(
    AnnotationActions.setBufferRegionParams,
    (state, { params }): AnnotationState => ({
      ...state,
      wave: {
        ...state.wave,
        bufferRegion: { ...state.wave.bufferRegion, ...params },
      },
    })
  ),
  on(
    AnnotationActions.setSegmentSaveLoading,
    (state, { loading }): AnnotationState => ({
      ...state,
      segments: { ...state.segments, saveLoading: loading },
    })
  ),
  on(
    AnnotationActions.setSegmentDeletionModalState,
    (state, { isOpen }): AnnotationState => ({
      ...state,
      segments: { ...state.segments, segmentDeletionModalState: isOpen },
    })
  ),
  on(
    AnnotationActions.setSegmentDeleteLoading,
    (state, { loading }): AnnotationState => ({
      ...state,
      segments: { ...state.segments, deleteLoading: loading },
    })
  ),
  on(
    AnnotationActions.setSegmentNavigationLoading,
    (state, { loading }): AnnotationState => ({
      ...state,
      segments: { ...state.segments, navigationLoading: loading },
    })
  ),
  on(
    AnnotationActions.setAdminList,
    (state, { adminList }): AnnotationState => ({ ...state, requestReview: { ...state.requestReview, adminList } })
  ),
  on(
    AnnotationActions.setAdminListLoading,
    (state, { loading }): AnnotationState => ({ ...state, requestReview: { ...state.requestReview, loading } })
  ),
  on(
    AnnotationActions.setSelectedReviewerId,
    (state, { reviewerId }): AnnotationState => ({
      ...state,
      requestReview: { ...state.requestReview, selectedReviewerId: reviewerId },
    })
  ),
  on(
    AnnotationActions.setReviewNote,
    (state, { reviewNote }): AnnotationState => ({
      ...state,
      requestReview: { ...state.requestReview, reviewNote },
    })
  ),

  on(
    AnnotationActions.setNotificationsList,
    (state, { notifications }): AnnotationState => ({
      ...state,
      reviewNotifications: { ...state.reviewNotifications, notifications },
    })
  ),

  on(
    AnnotationActions.setNotificationsLoading,
    (state, { loading }): AnnotationState => ({
      ...state,
      reviewNotifications: { ...state.reviewNotifications, loading },
    })
  ),
  on(
    AnnotationActions.setSelectedNotification,
    (state, { notification: selectedNotification }): AnnotationState => ({
      ...state,
      reviewNotifications: { ...state.reviewNotifications, selectedNotification },
    })
  ),
  on(
    AnnotationActions.updateNotification,
    (state, { id, status }): AnnotationState => ({
      ...state,
      reviewNotifications: {
        ...state.reviewNotifications,
        notifications: state.reviewNotifications.notifications.map((notification) =>
          notification.id === id ? { ...notification, status } : notification
        ),
      },
    })
  )
);
