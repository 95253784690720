import { createAction, props } from '@ngrx/store';
import { ProjectsDashboardEnum } from 'src/app/enums/store/actions.enum';
import { PaginationQueryInterface } from 'src/app/interfaces/dashboard/admin-files.interface';
import { DynamicTableInterface } from 'src/app/interfaces/shared/dynamic-table.interface';

export const loadProjects = createAction(
  ProjectsDashboardEnum.LOAD_PROJECTS,
  props<{ pagination: PaginationQueryInterface }>()
);
export const setProjectsList = createAction(
  ProjectsDashboardEnum.SET_PROJECTS_LIST,
  props<{ projectsList: DynamicTableInterface }>()
);
export const setProjectsLisLoading = createAction(
  ProjectsDashboardEnum.SET_PROJECTS_LOADING,
  props<{ isLoading: boolean }>()
);
