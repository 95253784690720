import { DynamicTableInterface, GetFilesListByUserQueryInterface } from '@interfaces';
import { createAction, props } from '@ngrx/store';
import { DashboardActionsEnum } from '@enums';
import { StatsState } from './dashboard.state';

// =============== Files ================== //
export const loadFiles = createAction(DashboardActionsEnum.LOAD_FILES);
export const setFilesLoading = createAction(DashboardActionsEnum.SET_FILES_LOADING, props<{ loading: boolean }>());
export const setFilesCount = createAction(DashboardActionsEnum.SET_FILES_COUNT, props<{ count: number }>());

export const setFilesUploadList = createAction(
  DashboardActionsEnum.SET_FILES_UPLOAD_LIST,
  props<{ filesUploadList: File[] }>()
);

export const setFilesUploadProgress = createAction(
  DashboardActionsEnum.SET_FILES_UPLOAD_PROGRESS,
  props<{ filesUploadProgress: Record<string, number> }>()
);

export const setFilesUploadComplete = createAction(
  DashboardActionsEnum.SET_FILES_UPLOAD_COMPLETE,
  props<{ completed: boolean }>()
);

// =============== Projects ================== //
export const loadProjects = createAction(DashboardActionsEnum.LOAD_PROJECTS);
export const setProjectsLoading = createAction(
  DashboardActionsEnum.SET_PROJECTS_LOADING,
  props<{ loading: boolean }>()
);
export const setProjectsCount = createAction(DashboardActionsEnum.SET_PROJECTS_COUNT, props<{ count: number }>());

// ============ Annotator ======================= //
export const loadAnnotatorFilesStats = createAction(DashboardActionsEnum.LOAD_ANNOTATOR_FILES_STATS);
export const setAnnotatorFilesStatsLoading = createAction(
  DashboardActionsEnum.SET_ANNOTATOR_FILES_STATS_LOADING,
  props<{ isLoading: boolean }>()
);
export const setAnnotatorFilesStats = createAction(
  DashboardActionsEnum.SET_ANNOTATOR_FILES_STATS,
  props<{ statistics: StatsState[] }>()
);

// =============== Users ================== //
export const loadUsers = createAction(DashboardActionsEnum.LOAD_USERS);
export const setUsersLoading = createAction(DashboardActionsEnum.SET_USERS_LOADING, props<{ loading: boolean }>());
export const setUsersCount = createAction(DashboardActionsEnum.SET_USERS_COUNT, props<{ count: number }>());
export const loadAssignedFilesListByUserId = createAction(
  DashboardActionsEnum.LOAD_ASSIGNED_FILES_LIST_BY_USER_ID,
  props<{ query: GetFilesListByUserQueryInterface }>()
);
export const setAssignedFilesListByUserIdLoading = createAction(
  DashboardActionsEnum.SET_ASSIGNED_FILES_LIST_BY_USER_ID_LOADING,
  props<{ isLoading: boolean }>()
);

export const setAnnotatorFilesStatsFailed = createAction(
  DashboardActionsEnum.SET_ASSIGNED_FILES_LIST_BY_USER_ID_FAILED,
  props<{ isFailed: boolean }>()
);
export const setAssignedFilesListByUserId = createAction(
  DashboardActionsEnum.SET_ASSIGNED_FILES_LIST_BY_USER_ID,
  props<{ list: DynamicTableInterface }>()
);
