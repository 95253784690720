import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './guards/auth/auth.guard';
import { UserResolver } from './resolvers/user.resolver';
import { AnnotationModulesEnum, RouteEnum } from './enums/navigation/route.enum';
import { AdminResolver } from './resolvers/admin.resolver';

const commonRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: RouteEnum.dashboard,
  },
  {
    path: RouteEnum.files,
    loadChildren: () => import('./components/files/files.module').then((m) => m.FilesModule),
    resolve: { userInfo: AdminResolver },
  },
  {
    path: RouteEnum.users,
    loadChildren: () => import('./components/users/users.module').then((m) => m.UsersModule),
    resolve: { userInfo: AdminResolver },
  },
  {
    path: RouteEnum.projects,
    loadChildren: () => import('./components/projects/projects.module').then((m) => m.ProjectsModule),
    resolve: { userInfo: AdminResolver },
  },
  {
    path: RouteEnum.annotation,
    loadChildren: () => import('./components/annotation/annotation.module').then((m) => m.AnnotationModule),
    resolve: { userInfo: UserResolver },
  },
  {
    path: RouteEnum.dashboard,
    loadChildren: () => import('./components/dashboard/dashboard.module').then((m) => m.DashboardModule),
    resolve: { userInfo: UserResolver },
  },
  {
    path: '**',
    redirectTo: RouteEnum.dashboard,
    pathMatch: 'full',
  },
];

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: RouteEnum.home,
  },

  {
    path: RouteEnum.signIn,
    loadChildren: () => import('./components/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: RouteEnum.home,
    loadChildren: () => import('./components/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
    resolve: { userInfo: UserResolver },
  },
  {
    path: AnnotationModulesEnum.IMAGE,
    canActivate: [AuthGuard],
    children: commonRoutes,
  },
  {
    path: AnnotationModulesEnum.AUDIO,
    canActivate: [AuthGuard],
    children: commonRoutes,
  },

  {
    path: '**',
    redirectTo: RouteEnum.home,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
