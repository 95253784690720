import { createReducer, on } from '@ngrx/store';
import { initialToolboxState, ToolboxState } from './toolbox.state';
import { ToolboxActions } from './action-types';
import { UploadStatusEnum } from 'src/app/enums/annotation/annotation.toolbox.enum';

export const toolboxFeatureKey = 'toolbox';

export const toolboxReducer = createReducer(
  initialToolboxState,
  on(
    ToolboxActions.setFilesList,
    (state, action): ToolboxState => ({
      ...state,
      filesTool: { ...state.filesTool, files: action.files },
    })
  ),
  on(
    ToolboxActions.setIsFilesLoading,
    (state, action): ToolboxState => ({
      ...state,
      filesTool: { ...state.filesTool, isFilesListLoading: action.isLoading },
    })
  ),
  on(
    ToolboxActions.setIsFilesLoaded,
    (state, action): ToolboxState => ({
      ...state,
      filesTool: { ...state.filesTool, isFilesListLoaded: action.isFilesLoaded },
    })
  ),
  on(
    ToolboxActions.setUsersList,
    (state, action): ToolboxState => ({
      ...state,
      filesTool: { ...state.filesTool, users: action.users },
    })
  ),
  on(
    ToolboxActions.setIsUsersLoading,
    (state, action): ToolboxState => ({
      ...state,
      filesTool: { ...state.filesTool, isUsersListLoading: action.isLoading },
    })
  ),
  on(
    ToolboxActions.setIsUsersLoaded,
    (state, action): ToolboxState => ({
      ...state,
      filesTool: { ...state.filesTool, isUsersListLoaded: action.isUsersLoaded },
    })
  ),
  on(
    ToolboxActions.setSelectedFile,
    (state, action): ToolboxState => ({
      ...state,
      filesTool: { ...state.filesTool, selectedFile: action.file },
    })
  ),
  on(
    ToolboxActions.setSelectedUser,
    (state, action): ToolboxState => ({
      ...state,
      filesTool: { ...state.filesTool, selectedUser: action.user },
    })
  ),
  // ================= Save Tool Reducer ========================
  on(
    ToolboxActions.uploadTranscriptFileRequest,
    (state): ToolboxState => ({
      ...state,
      saveTool: {
        ...state.saveTool,
        importFile: {
          status: UploadStatusEnum.REQUESTED,
          progress: null,
          error: null,
        },
      },
    })
  ),
  on(
    ToolboxActions.uploadTranscriptFileStatus,
    (state, { status, progress, error }): ToolboxState => ({
      ...state,
      saveTool: {
        ...state.saveTool,
        importFile: {
          status,
          progress,
          error,
        },
      },
    })
  ),
  on(
    ToolboxActions.uploadTranscriptFileCancel,
    (state): ToolboxState => ({
      ...state,
      saveTool: {
        ...state.saveTool,
        importFile: {
          status: UploadStatusEnum.READY,
          progress: null,
          error: null,
        },
      },
    })
  ),
  // ================= Zoom Tool Reducer ========================
  on(
    ToolboxActions.setHorizontalZoom,
    (state, { value }): ToolboxState => ({
      ...state,
      commonTools: { ...state.commonTools, horizontalZoom: value },
    })
  ),
  on(
    ToolboxActions.setVerticalZoom,
    (state, { value }): ToolboxState => ({
      ...state,
      commonTools: { ...state.commonTools, verticalZoom: value },
    })
  ),
  on(
    ToolboxActions.setFileStatus,
    (state, { fileStatus }): ToolboxState => ({
      ...state,
      filesTool: {
        ...state.filesTool,
        selectedFile: {
          ...state.filesTool.selectedFile,
          status: fileStatus,
        },
        files: state.filesTool.files.map((file) =>
          file.id === state.filesTool.selectedFile.id ? { ...file, status: fileStatus } : file
        ),
      },
    })
  )
);
