import { DashboardState } from './dashboard.state';
import { createReducer, on } from '@ngrx/store';
import { DashboardActions } from './action-types';

export const dashboardFeatureKey = 'dashboard';
export const dashboardInitialState: DashboardState = {
  admin: {
    files: {
      filesUploadList: null,
      filesUploadProgress: null,
      count: null,
      list: null,
      isLoading: false,
      filesUploadCompleted: false,
    },
    projects: { list: null, count: null, isLoading: false },
    users: { list: null, count: null, isLoading: false },
  },
  annotator: {
    filesStatusStats: {
      statistics: [],
      isLoading: false,
      isFailed: false,
    },
  },
  user: {
    assignedFiles: {
      list: null,
      isLoading: false,
    },
  },
};

export const dashboardReducer = createReducer(
  dashboardInitialState,

  // =========== Files ============== //
  on(
    DashboardActions.setFilesLoading,
    (state, { loading: isLoading }): DashboardState => ({
      ...state,
      admin: {
        ...state.admin,
        files: {
          ...state.admin.files,
          isLoading,
        },
      },
    })
  ),
  on(
    DashboardActions.setFilesCount,
    (state, { count }): DashboardState => ({
      ...state,
      admin: {
        ...state.admin,
        files: {
          ...state.admin.files,
          count,
        },
      },
    })
  ),
  on(
    DashboardActions.setFilesUploadList,
    (state, { filesUploadList }): DashboardState => ({
      ...state,
      admin: {
        ...state.admin,
        files: {
          ...state.admin.files,
          filesUploadList,
        },
      },
    })
  ),
  on(
    DashboardActions.setFilesUploadProgress,
    (state, { filesUploadProgress }): DashboardState => ({
      ...state,
      admin: {
        ...state.admin,
        files: {
          ...state.admin.files,
          filesUploadProgress,
        },
      },
    })
  ),
  on(
    DashboardActions.setFilesUploadComplete,
    (state, { completed }): DashboardState => ({
      ...state,
      admin: {
        ...state.admin,
        files: {
          ...state.admin.files,
          filesUploadCompleted: completed,
        },
      },
    })
  ),

  // =========== Users ============= //
  on(
    DashboardActions.setUsersLoading,
    (state, { loading: isLoading }): DashboardState => ({
      ...state,
      admin: {
        ...state.admin,
        users: {
          ...state.admin.users,
          isLoading,
        },
      },
    })
  ),
  on(
    DashboardActions.setUsersCount,
    (state, { count }): DashboardState => ({
      ...state,
      admin: {
        ...state.admin,
        users: {
          ...state.admin.users,
          count,
        },
      },
    })
  ),

  // =========== Projects ============= //
  on(
    DashboardActions.setProjectsLoading,
    (state, { loading: isLoading }): DashboardState => ({
      ...state,
      admin: {
        ...state.admin,
        projects: {
          ...state.admin.projects,
          isLoading,
        },
      },
    })
  ),
  on(
    DashboardActions.setProjectsCount,
    (state, { count }): DashboardState => ({
      ...state,
      admin: {
        ...state.admin,
        projects: {
          ...state.admin.projects,
          count,
        },
      },
    })
  ),
  // ============= Annotator Dashboard =============== //
  on(
    DashboardActions.setAnnotatorFilesStatsLoading,
    (state, { isLoading }): DashboardState => ({
      ...state,
      annotator: {
        ...state.annotator,
        filesStatusStats: {
          ...state.annotator.filesStatusStats,
          isLoading,
        },
      },
    })
  ),
  on(
    DashboardActions.setAnnotatorFilesStats,
    (state, { statistics }): DashboardState => ({
      ...state,
      annotator: {
        ...state.annotator,
        filesStatusStats: {
          ...state.annotator.filesStatusStats,
          statistics,
          isFailed: false,
        },
      },
    })
  ),
  on(
    DashboardActions.setAnnotatorFilesStatsFailed,
    (state, { isFailed }): DashboardState => ({
      ...state,
      annotator: {
        ...state.annotator,
        filesStatusStats: {
          ...state.annotator.filesStatusStats,
          isFailed,
        },
      },
    })
  ),
  // ========== Common DashBoard ============= //
  on(
    DashboardActions.setAssignedFilesListByUserIdLoading,
    (state, { isLoading }): DashboardState => ({
      ...state,
      user: {
        ...state.user,
        assignedFiles: {
          ...state.user.assignedFiles,
          isLoading,
        },
      },
    })
  ),
  on(
    DashboardActions.setAssignedFilesListByUserId,
    (state, { list }): DashboardState => ({
      ...state,
      user: {
        ...state.user,
        assignedFiles: {
          ...state.user.assignedFiles,
          list: list,
        },
      },
    })
  )
);
