import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, map, Observable, throwError } from 'rxjs';
import { StoreService } from '../services/store/store.service';
import { AuthService } from '../services/api/auth/auth.service';
import { UserInterface } from '../interfaces/auth/user.interface';
import { memoizedUserinfo } from './resolvers.utils';
import { RouteEnum } from '../enums/navigation/route.enum';

@Injectable({
  providedIn: 'root',
})
export class UserResolver implements Resolve<boolean> {
  constructor(private storeService: StoreService, private authService: AuthService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return memoizedUserinfo(this.authService, this.storeService)
      .pipe(map((userInfo: UserInterface) => !!userInfo))
      .pipe(
        catchError((error) => {
          this.router.navigate([RouteEnum.signIn]);
          return throwError(error);
        })
      );
  }
}
