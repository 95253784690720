declare var process: {
  env: {
    BFF_URL: string;
    LOCAL_BFF_URL: string;
    CLIENT_URL: string;
  };
};

export const environmentVariables = {
  BFF_URL: process.env.BFF_URL,
  LOCAL_BFF_URL: process.env.LOCAL_BFF_URL,
  CLIENT_URL: process.env.CLIENT_URL,
};

export const commonEnv: Partial<Environment> = {
  API_URL: environmentVariables.BFF_URL,
  CLIENT_URL: environmentVariables.CLIENT_URL,
};

export interface Environment {
  API_URL: string;
  CLIENT_URL: string;
  production: boolean;
}
