import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  NotificationRequestInterface,
  NotificationResponseInterface,
  NotificationStatusEnum,
  NotificationStreamInterface,
} from '@interfaces';
import { catchError, fromEvent, map, Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private httpClient: HttpClient) {}

  pushNotification(notification: NotificationRequestInterface): Observable<unknown> {
    const endpoint = `api/notifications/push`;
    return this.httpClient.post(endpoint, notification);
  }

  getNotification(adminId: string): Observable<NotificationStreamInterface> {
    const endpoint = `api/notifications/v2/consume/${adminId}`;
    return this.httpClient.get<NotificationStreamInterface>(endpoint);
  }

  consumeNotifications(adminId: string, limit = 5): Observable<NotificationResponseInterface[]> {
    const source = new EventSource(`${environment.API_URL}wss/notifications/v3/consume/${adminId}?limit=${limit}`);
    return fromEvent<MessageEvent>(source, 'message').pipe(
      map((event) => ('data' in event ? JSON.parse(event.data).map(JSON.parse) : null)),
      catchError(() => of([]))
    );
  }

  updateNotificationStatus(notificationId: string, status: NotificationStatusEnum): Observable<unknown> {
    const endpoint = `api/notifications/${notificationId}/status/${status}`;
    return this.httpClient.patch(endpoint, null);
  }

  getNotificationBeforeTimestamp(
    adminId: string,
    reportTime: string,
    limit = 5
  ): Observable<NotificationResponseInterface[]> {
    const endpoint = `api/notifications/${adminId}`;
    let params: HttpParams = new HttpParams();
    params = params.set('report_time', reportTime);
    params = params.set('limit', limit);
    return this.httpClient.get<NotificationResponseInterface[]>(endpoint, { params });
  }
}
