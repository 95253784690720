import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { setTheme } from './app.actions';
import { tap } from 'rxjs';
import { ThemeService } from '../services/worker/theme/theme.service';

@Injectable()
export class AppEffects {
  setThemeEffect$;
  constructor(private actions$: Actions, private themeService: ThemeService) {
    this.setThemeEffect$ = createEffect(
      () =>
        this.actions$.pipe(
          ofType(setTheme),
          tap(({ selectedTheme }) => this.themeService.selectTheme(selectedTheme))
        ),
      { dispatch: false }
    );
  }
}
