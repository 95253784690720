import { createAction, props } from '@ngrx/store';
import { SignInResponseInterface } from '../../../interfaces/auth/sign-in.interface';
import { UserInterface } from '../../../interfaces/auth/user.interface';

export const login = createAction('[Login Page] User Login');
export const loginSuccess = createAction('[Login Page] User Login Success', props<{ user: SignInResponseInterface }>());
export const loginFail = createAction('[Login Page] User Login Fail', props<{ error: string }>());

export const setUserInfo = createAction('[Login Page] Set User Info', props<{ userInfo: UserInterface }>());

export const logout = createAction('[Top Menu] Logout');
