import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SignInResponseInterface } from '../../../interfaces/auth/sign-in.interface';
import { UserInterface } from '../../../interfaces/auth/user.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private httpClient: HttpClient) {}

  login(email: string, password: string): Observable<SignInResponseInterface> {
    const endpoint = 'api/login';
    return this.httpClient.post<SignInResponseInterface>(endpoint, { email, password });
  }

  getUserInfo(id: string): Observable<UserInterface> {
    const endpoint = `api/get_user/${id}`;
    return this.httpClient.get<UserInterface>(endpoint);
  }
}
