import { concatMap, Observable, of, switchMap, tap } from 'rxjs';
import { UserInterface } from '../interfaces/auth/user.interface';
import { AuthService } from '../services/api/auth/auth.service';
import { StoreService } from '../services/store/store.service';

export function memoizedUserinfo(authService: AuthService, storeService: StoreService): Observable<UserInterface> {
  const fetchCurrentUser$: Observable<UserInterface> = storeService.getCurrentUser().pipe(
    switchMap(({ id }) => authService.getUserInfo(id)),
    tap((userInfo: UserInterface) => storeService.setUserInfo(userInfo))
  );

  const getCurrentUserFromStore$: Observable<UserInterface | undefined> = storeService.getCurrentUserInfo();

  return getCurrentUserFromStore$.pipe(concatMap((userInfo) => (!!userInfo ? of(userInfo) : fetchCurrentUser$)));
}
