import { createReducer, on } from '@ngrx/store';
import { AuthState } from './auth.state';
import { AuthActions } from './action-types';

export const authFeatureKey = 'auth';
export const initialAuthState: AuthState = {
  user: undefined,
  userInfo: undefined,
  loading: false,
  error: '',
};
export const authReducer = createReducer(
  initialAuthState,
  on(AuthActions.login, (state) => ({ ...state, loading: true, error: '' })),

  on(AuthActions.loginSuccess, (state, action) => ({
    ...state,
    user: action.user,
    loading: false,
  })),

  on(AuthActions.loginFail, (state, action) => ({
    ...state,
    error: action.error,
    loading: false,
  })),

  on(AuthActions.logout, (state) => ({ ...state, user: undefined, userInfo: undefined })),

  on(AuthActions.setUserInfo, (state, { userInfo }) => ({ ...state, userInfo }))
);
