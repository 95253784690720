import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthWorkerService } from '../services/worker/auth/auth-worker.service';
import { StoreService } from '../services/store/store.service';
import { ToastTypesEnum } from '../enums/shared/toast-types.enum';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private baseUrl = environment.API_URL;

  constructor(private authWorkerService: AuthWorkerService, private storeService: StoreService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.headers.has('Content-Type') && !(request.url.includes('load_file') || request.url.includes('load'))) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json'),
      });
    }
    if (request.url.startsWith('api')) {
      const token = this.authWorkerService.getAccessToken();
      request = request.clone({
        url: `${this.baseUrl}${request.url}`,
        setHeaders: {
          authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request).pipe(
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      catchError((error: HttpErrorResponse) => {
        const message =
          error.status === 401
            ? 'Your session has expired! Please sign in'
            : `<b>${error.status}: ${error.statusText}</b></br>${error.url}</br>`;
        this.storeService.setGlobalToast({
          message,
          type: error.status === 401 ? ToastTypesEnum.info : ToastTypesEnum.error,
        });
        if (error.status === 401) {
          this.storeService.logout();
        }
        return throwError(() => error);
      })
    );
  }
}
