import { UsersDashboardState } from './users.state';
import { createReducer, on } from '@ngrx/store';
import { UserDashboardAction } from './action-types';

export const usersDashboardFeatureKey = 'usersDashboard';
export const usersDashboardInitialState: UsersDashboardState = {
  usersList: null,
  loading: false,
};

export const usersDashboardReducer = createReducer(
  usersDashboardInitialState,
  on(UserDashboardAction.setUsers, (state, { usersList }): UsersDashboardState => ({ ...state, usersList })),
  on(UserDashboardAction.setUsersLoading, (state, { loading }): UsersDashboardState => ({ ...state, loading }))
);
