import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { ThemeService } from './services/worker/theme/theme.service';
import { filter, from, map, Observable, of, switchMap, take, timer, zip } from 'rxjs';
import { StoreService } from './services/store/store.service';
import { AuthWorkerService } from './services/worker/auth/auth-worker.service';
import { AnnotationModulesEnum, ToastTypesEnum } from '@enums';
import { ToastInterface } from '@interfaces';
import { isNonNull } from './utils';

@Component({
  selector: 'at-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  loading$: Observable<boolean>;
  currentModule$: Observable<AnnotationModulesEnum> = this.storeService.getCurrentAnnotationModule();
  toast$: Observable<ToastInterface> = this.storeService.getGlobalToast().pipe(filter(isNonNull));
  isToast$: Observable<boolean> = this.storeService.getGlobalToast().pipe(
    switchMap((toast) => (!!toast ? zip(from([toast, null]), timer(0, 3000)).pipe(take(2)) : of(null))),
    map((v) => (!!v ? v[0] : v)),
    map((toast) => !!toast)
  );
  toastMessage$: Observable<string> = this.toast$.pipe(map((toast) => toast.message));
  toastType$: Observable<ToastTypesEnum> = this.toast$.pipe(map((toast) => toast.type));

  constructor(
    private router: Router,
    private themeService: ThemeService,
    private storeService: StoreService,
    private authWorkerService: AuthWorkerService
  ) {
    this.themeService.preSelectTheme();
    const user = this.authWorkerService.getCurrentUser();
    if (user) {
      this.storeService.loginSuccess(user);
    }
    this.loading$ = this.router.events.pipe(
      filter(
        (event) =>
          event instanceof NavigationStart ||
          event instanceof NavigationEnd ||
          event instanceof NavigationError ||
          event instanceof NavigationCancel
      ),
      map((event) => event instanceof NavigationStart)
    );
  }

  closeToast(): void {
    this.storeService.setGlobalToast(null);
  }
}
