import { createAction, props } from '@ngrx/store';
import { UsersDashboardActionEnum } from '@enums';
import { DynamicTableInterface, DynamicTableRequestInterface } from '@interfaces';

export const loadUsers = createAction(
  UsersDashboardActionEnum.LOAD_USERS,
  props<{ request: DynamicTableRequestInterface }>()
);

export const setUsers = createAction(
  UsersDashboardActionEnum.SET_USERS_LIST,
  props<{ usersList: DynamicTableInterface }>()
);

export const setUsersLoading = createAction(
  UsersDashboardActionEnum.SET_USERS_LIST_LOADING,
  props<{ loading: boolean }>()
);
