import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { map, of, switchMap, tap } from 'rxjs';
import { AuthActions } from './action-types';
import { AuthWorkerService } from '../../../services/worker/auth/auth-worker.service';
import { StoreService } from '../../../services/store/store.service';
import { NotificationService } from '../../../services/api/annotation/notification.service';
import { UserRolesEnum } from '@enums';
import { NotificationResponseInterface } from '@interfaces';
import { AnnotationActions } from '../../annotation/store/action-types';

@Injectable()
export class AuthEffects {
  login$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap((action) => this.authWorkerService.storeCurrentUser(action.user))
      );
    },
    { dispatch: false }
  );

  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => {
          this.authWorkerService.clearCurrentUser();
          this.router.navigate(['sign-in']);
        })
      );
    },
    { dispatch: false }
  );

  setUserInfoEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.setUserInfo),
        switchMap(({ userInfo: { role, id } }) =>
          role === UserRolesEnum.ADMIN
            ? this.notificationService.consumeNotifications(id, 2)
            : of<NotificationResponseInterface[]>([])
        ),
        concatLatestFrom(() => this.storeService.getNotifications()),
        map(([newNotifications, oldNotifications]) =>
          AnnotationActions.setNotificationsList({ notifications: [...newNotifications, ...oldNotifications] })
        )
      );
    },
    { dispatch: true }
  );
  constructor(
    private actions$: Actions,
    private router: Router,
    private authWorkerService: AuthWorkerService,
    private storeService: StoreService,
    private notificationService: NotificationService
  ) {}
}
