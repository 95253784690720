import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FilesDashboardState } from './files.state';
import { filesDashboardFeatureKey } from './files.reducer';

export const selectDashboardState = createFeatureSelector<FilesDashboardState>(filesDashboardFeatureKey);
export const selectFileDashboardProjectsState = createSelector(selectDashboardState, ({ projects }) => projects);
export const selectFileDashboardUsersState = createSelector(selectDashboardState, ({ users }) => users);
export const selectFilesList = createSelector(selectDashboardState, ({ filesList }) => filesList);
export const selectFilesLoading = createSelector(selectDashboardState, ({ loading }) => loading);
export const selectProjectsList = createSelector(selectFileDashboardProjectsState, ({ projectList }) => projectList);
export const selectProjectsLoading = createSelector(selectFileDashboardProjectsState, ({ loading }) => loading);
export const selectUsersList = createSelector(selectFileDashboardUsersState, ({ usersList }) => usersList);
export const selectUsersLoading = createSelector(selectFileDashboardUsersState, ({ loading }) => loading);
