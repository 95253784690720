export enum AnnotationToolsEnum {
  segmentTool = 'segment',
  fileTool = 'file',
  transcriptionTool = 'transcription',
  saveTool = 'save',
  zoomTool = 'zoom',
  commonTool = 'common',
}

export enum TranscriptionDirectionEnum {
  rtl = 'rtl',
  ltr = 'ltr',
}
